<template>
    <UnpicImage
        :layout="layout"
        :width="width"
        :height="height"
        v-bind="attributes"
        class="asset-cdn-image"
        @load="
            loadState = loadState === 'loading' ? 'loaded' : loadState;
            $emit('load');
        "
        @error="
            loadState = 'error';
            $emit('error');
        "
    />
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent, computed, ref, watch, reactive } from 'vue';
import { uniq } from 'lodash-es';
// @ts-ignore cannot properly resolve package types
import { Image as UnpicImage } from '@unpic/vue/base';
import { isStudyriveCdnFile, transformUrl, BREAKPOINTS, isBuilderCdnFile } from '@/utils/cdnUtils';
import { featureIsEnabled } from '@/modules/features';

const screenBreakpoints = BREAKPOINTS.filter((breakpoint) => breakpoint >= 256);
const biggestBreakpoint = BREAKPOINTS[screenBreakpoints.length - 1];
const supportedScales = [
    1,
    1.25,
    1.5,
    2,
    2.5,
    3,
] as const;

type LoadState = 'loading' | 'loaded' | 'error';

/**
 * This is a wrapper component for the unpic Image component to handle studydrive cdn images.
 * It automatically transforms the image url and injects the required width/height attributes
 * needed for an optimal image loading.
 * It will not manipulate non cdn images and keep them as-is, so those are also safe to pass here :)
 *
 * It will automatically crop images based on the dimension, layout & object-fit values, so you don't need to worry about that anymore.
 *
 * Read more about the concept and available props here: https://unpic.pics/img/vue/
 */
export default defineComponent({
    name: 'AssetCdnImage',
    components: {
        UnpicImage,
    },
    props: {
        /**
         * The source of the image
         */
        src: {
            type: String,
            default: undefined,
        },
        /**
         * Defines how the image is rendered as part of the layout
         */
        layout: {
            // the type is specified in the package
            type: String as PropType<'fixed' | 'constrained' | 'fullWidth'>,
            default: undefined,
        },
        /**
         * Defines the visual object fit of the image
         */
        objectFit: {
            // the type is specified in the package
            type: String as PropType<'contain' | 'cover' | 'fill' | 'none' | 'scale-down' | 'inherit' | 'initial'>,
            default: undefined,
        },
        /**
         * Defines the intended width of the image
         */
        width: {
            type: Number as PropType<number>,
            default: undefined,
        },
        /**
         * Defines the intended height of the image
         */
        height: {
            type: Number as PropType<number>,
            default: undefined,
        },
    },
    emits: ['load', 'error'],
    setup(props) {
        // Specify image cropping for fixed dimensions and cover object fit.
        // For other object-fit:cover scenarios and unfixed dimensions, it might be better to not crop as the html element could shange in size dynamically and reveal more/less from the original image.
        // So it would be better to load as much from the image as possible then.
        const crop = computed(
            () =>
                props.width &&
                props.height &&
                props.layout !== 'fullWidth' &&
                // note: cover is default behavior
                (!props.objectFit || props.objectFit === 'cover'),
        );

        const loadState = ref<LoadState>('loading');
        // resetting the laoding state when the src changes
        watch(
            () => props.src,
            () => {
                loadState.value = 'loading';
            },
        );

        const isBuilderCdn = computed(() => props.src && isBuilderCdnFile(props.src));

        const breakpoints = computed(() => {
            if (
                !props.src ||
                loadState.value === 'error' ||
                !featureIsEnabled('imageHandler') ||
                !isStudyriveCdnFile(props.src)
            ) {
                // fallback to unpic breakpoints if the src is a different cdn file
                // otherwise, don't use any breakpoints
                return isBuilderCdn.value ? undefined : [];
            }

            if (props.layout === 'fullWidth') return screenBreakpoints;

            if (props.width) {
                const desiredBreakpoints = supportedScales.map((scale) => props.width! * scale);

                // find the next bigger size and remove duplicates
                return uniq(desiredBreakpoints.map((db) => BREAKPOINTS.find((b) => b >= db) ?? biggestBreakpoint));
            }

            // this will fall back to the unpic breakpoints
            return undefined;
        });
        return {
            loadState,
            attributes: reactive({
                src: computed(() =>
                    loadState.value === 'error'
                        ? '/images/placeholders/fallback_image_no_text_transparent.svg'
                        : props.src,
                ),
                breakpoints,
                transformer: transformUrl,
                objectFit: computed(() => (loadState.value === 'error' ? 'scale-down' : props.objectFit)),
                operations: computed(() =>
                    isBuilderCdn.value
                        ? {
                              fit: crop.value ? 'cover' : 'contain',
                          }
                        : {
                              m: crop.value ? 'cover' : undefined,
                          },
                ),
            }),
        };
    },
});
</script>
<style lang="scss" scoped>
.asset-cdn-image {
    image-rendering: auto;
}
</style>
