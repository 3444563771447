<template>
    <div
        v-bind="tagListAttrs"
        class="tag-list flex space-x-2 items-center"
    >
        <Tag
            v-for="tag in formattedTags"
            :key="`${tag.value}-${tag.icon}`"
            :text="tag.value"
            :icon="tag?.icon"
            v-bind="tagAttrs"
            :icon-class="tag?.iconClass"
        />
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import { pickBy } from 'lodash-es';
import { convertToReadableNumber } from '@/helpers/string';
import type { TagBase } from './TagList';
/**
 * TagList uses Tag component to display list of tags, each tag has text, icon & iconClass properties to have
 * full control and flexibility over displaying & styling
 */
export default defineComponent({
    name: 'TagList',
    inheritAttrs: false,
    props: {
        /** array of tags */
        tags: {
            type: Array as PropType<TagBase[]>,
            required: true,
        },
    },
    computed: {
        tagAttrs(): Record<string, unknown> {
            return Object.fromEntries(
                Object.entries(this.$attrs)
                    .filter(([key]) => key.startsWith('tag-'))
                    .map(([key, value]) => [key.replace('tag-', ''), value]),
            );
        },
        tagListAttrs(): Record<string, unknown> {
            return pickBy(this.$attrs, (value, key) => !key.startsWith('tag-'));
        },
        formattedTags(): TagBase[] {
            return this.tags.map((tag) =>
                typeof tag.value === 'number' ? { ...tag, value: convertToReadableNumber(tag.value) } : tag,
            );
        },
    },
});
</script>
