// polyfills
// eslint-disable-next-line
import 'vite/modulepreload-polyfill';
import 'core-js';
import 'navigator.locks';
import smoothscroll from 'smoothscroll-polyfill';

// css
import '../sass/main.scss';

// external modules
import { createApp } from 'vue';

// external plugins
import PortalVue from 'portal-vue';
import Vue3TouchEvents from 'vue3-touch-events';
import VueLazyload from 'vue-lazyload';
import { createHead } from '@unhead/vue';

import { backend } from '@/api/backend';

// internal/adjusted plugins
import PrimeVue from '@plugins/primevue';
import I18NextPlugin from '@/plugins/i18n';
import VueQueryPlugin from '@/plugins/vue-query';
import FloatingVue from '@/plugins/floatingVue';
import ModalPlugin from '@/plugins/modal';
import IdPlugin from '@/plugins/id';
import EventBusPlugin from '@/plugins/eventBus';
import SentryPlugin from '@/plugins/sentry';
import '@/plugins/dayjs';

// directives
import Directives from '@directives';

// components
import Components from '@components';
import App from '@/App.vue';

// store
import store from '@/store';
import stores from '@/stores';

// routing
import router from '@router';

// internal modules
import '@/modules/braze';
import { registerGlobalErrorHandler } from '@/utils/errorUtils';

// provides smooth scrolling backwards compatibility
smoothscroll.polyfill();

/* root component options */
const app = createApp(App);

registerGlobalErrorHandler();

/*
 * GLOBAL PROPERTIES
 */
app.config.globalProperties.$http = backend;
/*
 * COMPONENTS
 */
Object.entries(Components).forEach(([name, def]) => app.component(name, def));

/*
 * DIRECTIVES
 */
Object.entries(Directives).forEach(([name, def]) => app.directive(name, def));

app.provide('app', app);

/*
 * PLUGINS
 */
// Sentry needs to be the first plugin to catch errors as early as possible
app.use(SentryPlugin);
app.use(VueQueryPlugin);
app.use(PrimeVue);
app.use(FloatingVue);
app.use(IdPlugin);
app.use(stores);
app.use(store);
app.use(I18NextPlugin);
app.use(PortalVue);
app.use(Vue3TouchEvents);
app.use(VueLazyload, {
    attempt: 1,
});
app.use(createHead());
app.use(router);
app.use(EventBusPlugin);
app.use(ModalPlugin); // keep this the last plugin
app.mount('#app');
