<template>
    <!-- eslint-disable-next-line vue/require-component-is -->
    <div class="flex w-auto">
        <VMenu
            :disabled="hideProfileCard"
            class="flex items-center profile-card-container overflow-hidden"
            theme="profileCard"
        >
            <VTooltip
                :disabled="!isAdmin || !isAnonymousUser || !anonymousUser || !disableCard"
                :popper-class="tooltipVariant"
                :delay="4000"
                class="flex-shrink-0"
            >
                <div
                    v-if="!isAnonymous"
                    :title="computedTitle"
                    :class="['relative', sizeClass, { 'cursor-pointer': link }]"
                    @click="onAvatarClick"
                >
                    <AssetLottie
                        v-if="animatedAvatar"
                        :src="animatedAvatar"
                        :class="['rounded-full', sizeClass]"
                    ></AssetLottie>
                    <img
                        v-else
                        :class="['rounded-full', sizeClass]"
                        :src="picture || defaultImage"
                        :alt="name"
                    />
                    <div
                        v-if="showAdminBadge"
                        class="absolute bottom-0 w-full h-3 bg-blue-600 rounded leading-3 text-center text-3xs text-slate-50"
                    >
                        {{ $t('common:admin')?.toUpperCase() }}
                    </div>
                </div>
                <span
                    v-else
                    :class="['bg-slate-300 flex items-center justify-center rounded-full', sizeClass]"
                >
                    <span class="icon icon-incognito text-slate-400 w-2/3 h-2/3"></span>
                </span>

                <template
                    v-if="anonymousUser"
                    #popper
                >
                    <a
                        :href="anonymousUser.profile_link"
                        target="_blank"
                        v-text="anonymousUser.user_id"
                    ></a>
                </template>
            </VTooltip>

            <div
                v-if="showDetails"
                class="pl-2 overflow-hidden w-full"
            >
                <div :class="[{ 'inline-block': isContextSlotDefined }]">
                    <component
                        :is="!isAnonymous && link ? 'a' : 'span'"
                        v-tooltip="{
                            content: isAdmin && anonymousUser && isAnonymousUser ? getAnonToolTipText : '',
                            theme: 'infoTooltip',
                        }"
                        :href="link"
                        :class="[
                            fontSizeClass,
                            { 'text-black': alternateUserNameColor },
                            'font-medium text-slate-600 leading-tight inline-flex items-baseline',
                        ]"
                    >
                        {{ name }}
                        <span class="labels">
                            <span
                                v-if="showKarmaPoints"
                                class="bg-purple-100 text-purple-700"
                            >
                                {{ karmaPoints }}
                            </span>
                            <slot name="labels"></slot>
                        </span>
                    </component>
                    <span
                        v-if="timestamp"
                        :class="[
                            'ml-1 text-xs leading-tight text-slate-400 whitespace-nowrap',
                            {
                                block: !dateInLine && !isContextSlotDefined,
                            },
                        ]"
                    >
                        {{ timestamp }}
                    </span>
                </div>
                <div
                    v-if="isContextSlotDefined"
                    :class="['leading-none flex w-full', fontSizeClass]"
                >
                    <slot name="context"></slot>
                </div>
            </div>
            <template #popper="{ shown }">
                <ProfileCard
                    v-if="!hideProfileCard && shown"
                    :data-testid="`profile-card-${userId}`"
                    :name="name"
                    :user-id="userId"
                    :avatar="picture"
                    :show-admin-badge="showAdminBadge"
                    :profile-link="link"
                    :animated-avatar="animatedAvatar"
                    :university="university"
                    :karma-points="karmaPoints"
                />
            </template>
        </VMenu>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import route from '@/router/route';
import { slotIsUsed } from '@/helpers/dom';
import { mapGetters } from 'vuex';

export const FONT_SIZES = {
    inherit: 'text-inherit',
    base: 'text-base',
    sm: 'text-sm',
};

export default {
    name: 'UserAvatar',
    components: {
        AssetLottie: defineAsyncComponent(() => import('@components/AssetLottie')),
        ProfileCard: defineAsyncComponent(() => import('@components/ProfileCard')),
    },
    props: {
        karmaPoints: {
            type: Number,
            default: null,
        },
        link: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: null,
        },
        animatedAvatar: {
            type: String,
            default: null,
        },
        picture: {
            type: String,
            default: null,
        },
        showDetails: {
            type: Boolean,
            default: false,
        },
        timestamp: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'default',
        },
        fontSize: {
            type: String,
            default: FONT_SIZES.sm,
            valdiator: (size) => Object.values(FONT_SIZES).includes(size),
        },
        isAnonymous: {
            type: Boolean,
            default: false,
        },
        anonymousUser: {
            type: Object,
            default: null,
        },
        anonymousRole: {
            type: String,
            default: '',
        },
        isAnonymousUser: {
            type: Boolean,
            default: false,
        },
        dateInLine: {
            type: Boolean,
            default: false,
        },
        alternateUserNameColor: {
            type: Boolean,
            default: false,
        },
        showAdminBadge: {
            type: Boolean,
            default: false,
        },
        university: {
            type: Object,
        },
        userId: {
            type: Number,
            default: null,
        },
        disableCard: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            defaultImage: '/images/avatars/default.svg',
        };
    },
    computed: {
        ...mapGetters('auth', ['isAdmin']),
        ...mapGetters({ currentUser: 'auth/user' }),
        computedTitle() {
            return this.title || this.name;
        },
        showKarmaPoints() {
            return this.karmaPoints !== null && this.karmaPoints !== undefined;
        },
        isContextSlotDefined() {
            return slotIsUsed(this.$slots.context);
        },
        hideProfileCard() {
            return (
                this.disableCard ||
                !this.currentUser ||
                !this.userId ||
                this.isAnonymousUser ||
                this.currentUser.userid === this.userId
            );
        },
        fontSizeClass() {
            switch (this.fontSize) {
                case FONT_SIZES.inherit:
                    return '';
                default:
                    return this.fontSize;
            }
        },
        sizeClass() {
            switch (this.size) {
                case 'xxs':
                    return 'w-7 h-7';
                case 'xs':
                    return 'w-8 h-8';
                case 'sm':
                    return 'w-8 h-8 md:w-9 md:h-9';
                case 'topbar':
                    return 'w-8 h-8 md:w-10 md:h-10';
                case 'comment':
                    return 'w-8 h-8';
                case 'default':
                    return 'w-9 h-9 md:w-10 md:h-10';
                case 'profile':
                    return 'w-16 h-16 md:w-22 md:h-22';
                default:
                    return this.size;
            }
        },
        tooltipVariant() {
            switch (this.anonymousRole) {
                case 'user':
                    return 'primary';
                case 'admin':
                    return 'danger';
                case 'KE':
                    return 'warning';
                default:
                    return undefined;
            }
        },
    },
    i18nOptions: {
        namespaces: ['common'],
    },
    methods: {
        getAnonToolTipText() {
            const params = {
                lang: 'en',
                slug: 'anon-admin',
                id: this.anonymousUser.user_id,
            };
            const url = route('profile.show', params);
            return `<a href='${url}' target='_blank'> User id: ${this.anonymousUser.user_id}</a>`;
        },
        onAvatarClick() {
            if (this.link) window.location.href = this.link;
        },
    },
};
</script>
<style lang="scss" scoped>
.labels {
    & > * {
        @apply font-light p-1 rounded-md text-xs ml-1 inline-block;
    }
}
</style>
