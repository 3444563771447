import { encode } from 'html-entities';

interface EncodeOptions {
    decodeQuotes?: boolean;
}

export function htmlEncode(content: string, options: EncodeOptions = { decodeQuotes: true }): string {
    let result = encode(content);
    if (options.decodeQuotes) {
        result = result.replace(/&apos;/g, '&#39;');
    }
    return result;
}

/**
 * Transforms a string into a URL-friendly slug, mimicking Laravel's Str::slug method
 * for when slug for given string is not provided by backend, accept the model name,title
 * as input & return slug string. It also remove the extension part if a filename is passed
 */
export function slugify(string: string, separator = '-', removeExtension = true): string {
    let slug = string.trim().toLowerCase();

    if (removeExtension && string.includes('.')) {
        slug = slug.substring(0, string.lastIndexOf('.'));
    }

    // convert all dashes/underscores into the separator
    const flip = separator === '-' ? '_' : '-';

    slug = slug.replace(new RegExp(`[${flip}]`, 'gu'), separator);

    // remove all characters that are not the separator, letters, numbers, or whitespace
    slug = slug.replace(new RegExp(`[^${separator}a-z0-9\\s]`, 'gu'), '');

    // replace all separator characters and whitespace by a single separator
    slug = slug.replace(new RegExp(`[${separator}\\s]+`, 'gu'), separator);

    return slug;
}

/**
 * Decode special character only to preview content with decoded entities
 * @param content
 * @return string
 */
export function decodeEntities(content: string): string {
    return content.replace(/&amp;/g, '&');
}

/**
 * Conver numbers above thousand and milion to shorter format e.g. 1,000,000 to 1M
 * @param number
 * @return {string}
 */
export function convertToReadableNumber(number: number | string): string {
    const convertedNumber = Math.abs(Number(number));

    if (Number.isNaN(convertedNumber)) {
        return '0';
    }

    switch (true) {
        case convertedNumber < 1000:
            return convertedNumber.toString();
        // 1.000 to 99.999, show 1 decimal place
        case convertedNumber < 100000:
            return `${Math.floor((convertedNumber / 1000) * 10) / 10}k`;
        // 100.000 to 999.999, no decimals
        case convertedNumber < 1000000:
            return `${Math.floor(convertedNumber / 1000)}k`;
        // Millions, no decimals
        default:
            return `${Math.floor(convertedNumber / 1000000)}m`;
    }
}

/**
 * Checks if the given string is >= than min and <= than max
 */
export function validateLength(string: string, min: number, max: number): boolean {
    return string.length >= min && string.length <= max;
}
