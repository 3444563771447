<template>
    <InputGroup
        :direction="screenSize === 'small' ? null : 'row'"
        :class="[
            'relative shadow-none rounded-l-md rounded-r-xl md:shadow-lg',
            { 'flex flex-col': screenSize === 'small' },
        ]"
    >
        <SearchElastic
            v-model:keyword="keyword"
            data-testid="search-form-global-input"
            class="w-full mb-2 rounded-md md:mb-0 md:mx-0 border-transparent shadow md:shadow-none"
            :search-context="[SearchContext.UNIVERSITIES, SearchContext.COURSES]"
            :empty-results="$t('search.noResults')"
            show-headers
            no-indicator
            disable-overlay
            truncate
            query-as-first-option
            temp-option-always
            @input="redirectToSearchPage"
        />

        <div
            v-show="!keyword"
            class="pointer-events-none absolute overflow-hidden mt-3.5 ml-4 font-body-1-medium"
        >
            <i18next :translation="$t('search.searchBar.input.searchForAny')">
                <template #type>
                    <TransitionRoll :items="placeholderItems">
                        <template #item="{ item }">
                            <span :style="item.style">{{ item.label }}</span>
                        </template>
                    </TransitionRoll>
                </template>
            </i18next>
        </div>

        <BtnCta
            data-testid="search-form-global-search-btn"
            class="whitespace-nowrap"
            right-icon="search"
            :label="screen.maxSM ? $t('global_search.cta') : null"
            @click="redirectToSearchPage"
        />
    </InputGroup>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { mapGetters } from 'vuex';
import InputGroup from '@/components/InputGroup';
import route from '@/router/route';
// eslint-disable-next-line no-restricted-imports
import type SearchElastic from '@/components/SearchElastic';
import { SearchContext } from '@/components/SearchElastic';
import type { SearchCourseResource } from '@/api/gateway/legacy-api/resources/SearchCourseResource';
import type { SearchUniversityResource } from '@/api/gateway/legacy-api/resources/SearchUniversityResource';
import TransitionRoll from '@/components/TransitionRoll';
import { colorToCss } from '@/utils/colorUtils';

/**
 * Standalone hero global search component.
 */
export default defineComponent({
    name: 'SearchGlobalHero',
    components: {
        InputGroup,
        TransitionRoll,
    },
    i18nOptions: {
        namespaces: ['common', 'homepage'],
    },
    setup() {
        return {
            SearchContext,
            keyword: ref<null | string>(null),
        };
    },
    computed: {
        ...mapGetters('ui', ['screenSize', 'screen']),
        placeholderItems() {
            return Object.values(this.$t('search.searchBar.input.searchForType', { returnObjects: true })).map(
                (item: { label: string; color: string }) => ({
                    label: item.label,
                    style: {
                        color: colorToCss(item.color),
                    },
                }),
            );
        },
    },
    methods: {
        redirectToSearchPage(option: SearchUniversityResource | SearchCourseResource) {
            window.location.href = route('search', {
                phrase: option.id ? undefined : this.keyword,
                university_id: option.type === 'university' ? option.id : option.university_id,
                course_id: option.type === 'course' ? option.id : undefined,
            });
        },
    },
});
</script>
