import { defineComponent } from 'vue';
import { debounce, throttle } from 'lodash-es';
import { removeFromArray } from '@/helpers/array';
import type { CancelTokenPromise } from '@/api/utils';

const rateLimitFunc = import.meta.env.MIX_SEARCH_RATE_LIMIT_TYPE === 'debounce' ? debounce : throttle;

/**
 * This mixin provides utility for creating a search component based on the Select component.
 *
 * There are two ways to start a search request based on this mixin. The first one is, if you have an individual Select component instance,
 * that triggers a search by typing into the input field. Which than emits the 'search' event. In this case you can simply provide a `search` method that returns a promise.
 *
 * The second case is if you need to trigger the search manually because it gets triggered by another components change or something else.
 * The entry point than to start the search is the `doSearch` method This triggers subsequentually the `search` method which holds
 * the api call defined in your component to execute the search.
 *
 * In both cases the returned promise can be formatted in the 'mapResponse' method or left as it is.
 * The formatted data is then available in the `options`for use in the component.
 *
 * You can set an axios CancelToken to cancel subsequent requests
 */
export default defineComponent({
    name: 'SearchMixin',
    emits: ['input', 'update:keyword'],
    data() {
        return {
            options: [] as unknown[],
            alreadySearched: false as boolean,
            requests: [] as Array<CancelTokenPromise<unknown>>,
            loadingCount: 0,
            keyword: null as string | null,
            searchDebounced: null as ReturnType<typeof rateLimitFunc> | null,
        };
    },
    computed: {
        isLoading(): boolean {
            return this.loadingCount > 0;
        },
    },
    watch: {
        keyword(val) {
            this.$emit('update:keyword', val);
        },
    },
    mounted() {
        // Via the leading & trailing flags, we also trigger searches on the first & last call.
        this.searchDebounced = rateLimitFunc(
            this.doSearch,
            parseInt(import.meta.env.MIX_SEARCH_RATE_LIMIT_DELAY || 0, 10),
            { trailing: true, leading: false },
        );
    },
    methods: {
        cancelLastRequest() {
            this.requests.forEach((req) => req.cancel);
        },
        onSearch(keyword: string | null) {
            const val = keyword?.trim();
            if (!val) {
                this.clear();
                return;
            }
            if (val.length < 3) {
                return;
            }
            this.keyword = keyword;
            if (this.searchDebounced) {
                this.searchDebounced(keyword);
            }
        },
        /**
         * trigger search function and store promise for later cancellation.
         * The implemented search function should return a CancelTokenPromise resolving to options
         * @param {string} keyword
         */
        doSearch(keyword: string | null) {
            this.keyword = keyword;
            this.loadingCount += 1;
            const req = this.search(keyword);
            this.requests.push(req);

            req.then((response: unknown) => {
                // abort if keyword was cleared in the meantime
                if (!this.keyword && this.keyword !== keyword) return;

                this.options = this.mapResponse(response);
                this.alreadySearched = true;
            }).finally(() => {
                this.requests = removeFromArray(req, this.requests);
                this.loadingCount -= 1;
            });
        },
        mapResponse(response: unknown) {
            return response as unknown[];
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        search(keyword: string | null): CancelTokenPromise<unknown> {
            // CancelTokenPromise
            throw new Error('Search method not implemented');
        },
        onInput(val: unknown) {
            this.$emit('input', val);
        },
        clear() {
            this.cancelLastRequest();
            this.options = [];
            this.keyword = null;
        },
    },
});
