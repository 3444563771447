<template>
    <div
        :class="[
            'selector',
            appearance,
            {
                slim,
                disabled,
                checked: state || checked,
                error: state === false && !checked,
                overlayed: showOverlay,
                'no-indicator': !showIndicator,
                'empty-dropdown': dropdownIsEmpty,
                'icon-only': iconOnly,
                'custom-background': !!background,
            },
        ]"
        :style="{
            '--selector-options-width': optionsWidth,
        }"
    >
        <ModalFullscreen
            v-if="opened && (searchable || filterable)"
            :title="overlayTitle || inputLabel || placeholder"
            :class="[
                'default overlayed selector',
                {
                    checked: state || checked,
                    error: state === false && !checked,
                    'no-indicator': !showIndicator,
                },
            ]"
            @close="close"
        >
            <div class="py-6 container">
                <PortalTarget name="overlay-select-input" />
                <slot
                    v-if="!loading && !options.length"
                    name="no-options"
                />
            </div>

            <div class="overflow-y-auto">
                <slot name="options-header" />
                <ul
                    :key="overlayOptionsRenderCount"
                    class="overlay-menu"
                >
                    <li
                        v-for="option in $refs.vselect?.filteredOptions"
                        :key="getId(option)"
                        :class="[{ header: option.isGroup }]"
                        @click.prevent="selectOption(option)"
                    >
                        <div
                            v-if="option[iconField]"
                            :class="['icon h-8 w-8 mr-3', option[iconField]]"
                        ></div>
                        <div v-text="option.isGroup ? option.labelField : option[labelField]"></div>
                    </li>
                    <slot name="options-footer" />
                </ul>
            </div>
        </ModalFullscreen>

        <portal
            to="overlay-select-input"
            :disabled="!opened || (!searchable && !filterable)"
        >
            <!-- eslint-disable -->
            <!-- native modifier has been removed, please confirm whether the function has been affected  -->
            <v-select
                ref="vselect"
                v-model="selectedOption"
                :placeholder="(opened && overlayPlaceholder) || placeholder"
                :label="labelField"
                :options="groupedOptions"
                :disabled="disabled"
                :filterable="filterable"
                :filter-by="filterBy"
                :searchable="searchable || filterable"
                :selectable="isOptionSelectable"
                :clearable="clearable"
                :style="maxHeightCssVar"
                :loading="loading && !openLoading"
                :name="name"
                :clear-search-on-blur="() => false"
                :autocomplete="autocomplete"
                :class="[{ [`rounded-${rounded}`]: rounded }, background, { clearable }]"
                :map-keydown="keydownHandler"
                @click.prevent="focusInput"
                @input="$emit('typed', $event)"
                @search="onSearch"
                @search:focus="(val) => $emit('search:focus', val)"
                @option:selected="selectOption"
                :close-on-select="closeOnSelect"
            >
                <!-- eslint-enable -->
                <template #list-header>
                    <slot name="options-header"></slot>
                </template>

                <template #selected-option="option">
                    <slot
                        name="selected-option"
                        :option="option"
                    >
                        <div class="flex w-full h-full items-center">
                            <span
                                v-if="useCustomIcon || icon || option[iconField]"
                                :class="['main-icon', 'flex', { 'mr-3': !iconOnly }]"
                            >
                                <slot
                                    name="icon"
                                    :option="option"
                                    is-selected
                                >
                                    <i :class="['icon', icon || option[iconField]]"></i>
                                </slot>
                            </span>
                            <div
                                v-if="!iconOnly"
                                :class="['selected-option-text', truncate ? 'truncate' : 'whitespace-normal']"
                                v-text="option[labelField]"
                            ></div>
                        </div>
                    </slot>
                </template>

                <template #search="{ attributes, events }">
                    <i
                        v-if="icon && (($refs.vselect && $refs.vselect.searching) || !modelValue)"
                        :class="['icon mr-3', icon]"
                        @click="iconClick"
                    ></i>
                    <input
                        :id="inputId"
                        ref="input"
                        :class="['vs__search', { 'text-slate-400': !selectedOption }]"
                        v-bind="attributes"
                        :required="required"
                        v-on="events"
                    />
                </template>

                <template #open-indicator>
                    <Spinner
                        v-if="openLoading && loading"
                        class="spinner"
                        color="blue"
                    ></Spinner>
                    <slot
                        v-else-if="showIndicator"
                        name="open-indicator"
                    >
                        <div
                            v-if="checked && (!$refs.vselect || !$refs.vselect.open)"
                            class="vs__open-indicator no-rotate flex p-1 border border-slate-200 rounded-full shadow-vertical-grey"
                        >
                            <i class="icon icon-checkmark-stroke w-3 h-3"></i>
                        </div>
                        <i
                            v-else-if="searchable"
                            class="vs__open-indicator no-rotate icon icon-search -mt-1"
                        ></i>
                    </slot>
                    <i v-else></i>
                </template>

                <template #no-options>
                    <slot name="no-options"></slot>
                </template>

                <template #option="option">
                    <div v-if="option.isGroup && (!option.isEmpty || option.emptyMsg)">
                        <slot
                            name="optionGroup"
                            :option="option"
                        >
                            <div class="pt-4 pb-2 pl-2 -mr-6 bg-slate-50 text-slate-400 uppercase text-sm">
                                <span v-text="option.labelField"></span>
                            </div>
                        </slot>
                        <slot
                            v-if="option.isEmpty"
                            name="emptyOptionGroup"
                            :option="option"
                        ></slot>
                    </div>
                    <slot
                        v-else
                        name="option"
                        :option="option"
                    >
                        <div class="flex p-3">
                            <span
                                v-if="useCustomIcon || option[iconField]"
                                class="mr-3 flex-shrink-0"
                            >
                                <slot
                                    name="icon"
                                    :option="option"
                                >
                                    <i :class="['icon ', option[iconField]]"></i>
                                </slot>
                            </span>
                            <div class="flex flex-col break-words">
                                <span v-text="option[labelField]"></span>
                                <span
                                    class="text-slate-400 text-sm"
                                    v-text="descriptionField && option[descriptionField]"
                                ></span>
                            </div>
                        </div>
                    </slot>
                </template>

                <template #list-footer>
                    <slot name="options-footer"></slot>
                </template>

                <template #spinner="spinner">
                    <Spinner
                        v-show="spinner.loading"
                        class="spinner"
                        color="blue"
                    ></Spinner>
                </template>
            </v-select>
        </portal>

        <!-- Select Overlay --->
        <template v-if="opened && !searchable && !filterable">
            <div :class="['selector-overlay', appearance, { opened }]">
                <div
                    class="backdrop w-full h-full absolute"
                    @click.prevent="close"
                ></div>

                <div
                    id="overlay-select"
                    class="overlay-menu absolute bottom-0 h-auto w-full overflow-y-auto bg-white"
                >
                    <div class="relative">
                        <slot name="options-header" />
                        <ul>
                            <li
                                v-for="option in options"
                                :key="getId(option)"
                                @click.prevent="selectOption(option)"
                            >
                                <div
                                    v-if="option[iconField]"
                                    :class="['icon h-8 w-8 mr-3', option[iconField]]"
                                ></div>
                                <div v-text="option[labelField]"></div>
                            </li>
                            <slot name="options-footer" />
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { mapGetters } from 'vuex';
import { defineAsyncComponent, nextTick } from 'vue';
import { provideInputLabelKey } from '@/components/keys';
import { IdGenerator } from '@/helpers/IdGenerator';
import { slotIsUsed } from '@/helpers/dom';

export default {
    name: 'Select',

    components: {
        VSelect,
        ModalFullscreen: defineAsyncComponent(() => import('@components/ModalFullscreen')),
    },
    inject: {
        inputLabel: {
            from: provideInputLabelKey,
            default: '',
        },
    },

    props: {
        appearance: {
            type: String,
            default: 'default',
            validator: (value) => ['default', 'menu'].includes(value),
        },
        options: {
            type: Array,
            default: () => [],
        },
        optionGroups: {
            type: Array,
            default: () => [],
            validator: (value) => {
                return value.every((group) => {
                    return group.id !== undefined && group.label !== undefined;
                });
            },
        },
        groupBy: {
            type: String,
            default: null,
        },
        // eslint-disable-next-line vue/require-prop-types
        modelValue: {
            default: null,
        },
        keydownHandler: {
            type: Function,
            default: (maps) => {
                return { ...maps };
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        inputId: {
            type: String,
            default: null,
        },
        maxHeightOptionContainer: {
            type: Number,
            default: 400,
        },
        iconField: {
            type: String,
            default: 'icon',
        },
        labelField: {
            type: String,
            default: 'label',
        },
        descriptionField: {
            type: String,
            default: null,
        },
        valueField: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        noIndicator: {
            type: Boolean,
            default: false,
        },
        /** fixed icon */
        icon: {
            type: String,
            default: null,
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        filterable: {
            type: Boolean,
            default: false,
        },
        disableOverlay: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        /**
         * when true, the dropdown will not close during the loading state
         */
        openLoading: {
            type: Boolean,
            default: false,
        },
        state: {
            type: [Boolean, null],
            default: null,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        rounded: {
            type: String,
            default: 'lg',
            validator: (val) =>
                [
                    'sm',
                    'md',
                    'lg',
                    'none',
                ].includes(val),
        },
        name: {
            type: String,
            default: undefined,
        },
        filterBy: {
            type: Function,
            default: undefined,
        },
        autocomplete: {
            type: String,
            default: undefined,
        },
        slim: {
            type: Boolean,
            default: false,
        },
        /** whether selected options should truncate or break */
        truncate: {
            type: Boolean,
            default: false,
        },
        overlayTitle: {
            type: String,
            default: null,
        },
        overlayPlaceholder: {
            type: String,
            default: null,
        },
        iconOnly: {
            type: Boolean,
            default: false,
        },
        optionsWidth: {
            type: String,
            default: 'auto',
        },
        background: {
            type: String,
            default: '',
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        closeOnSelect: {
            type: [Boolean, undefined],
            default: undefined,
        },
        editableInput: {
            type: Boolean,
            default: false,
        },
        clearBtnTestId: {
            type: String,
            default: undefined,
        },
    },
    emits: [
        'open',
        'close',
        'input',
        'update:modelValue',
        'search',
        'search:focus',
        'option:selected',
        'icon-click',
        'clear',
        'typed',
    ],

    data() {
        return {
            opened: false,
            selectedOption: null,
            overlayOptionsRenderCount: 0,
        };
    },

    computed: {
        ...mapGetters('ui', ['screen']),
        showOverlay() {
            // there currently exist no input
            return !this.disableOverlay && this.screen.maxSM;
        },
        showIndicator() {
            return !this.noIndicator && (!this.opened || this.searchable);
        },
        maxHeightCssVar() {
            return {
                '--max-height': `${this.maxHeightOptionContainer}px`,
            };
        },
        useCustomIcon() {
            return slotIsUsed(this.$slots.icon);
        },
        dropdownIsEmpty() {
            return (
                !this.options.length &&
                !slotIsUsed(this.$slots['no-options']) &&
                !slotIsUsed(this.$slots['options-footer'])
            );
        },
        groupedOptions() {
            if (this.optionGroups.length === 0 || this.options.length === 0) return this.options;

            const groupWithOptions = this.optionGroups.map((group) => {
                const optionsFromGroup = this.options.filter((option) => option[this.groupBy] === group.id);
                if (optionsFromGroup.length > 0 || group.emptyMsg) {
                    return [
                        {
                            isGroup: true,
                            labelField: group.label,
                            isEmpty: optionsFromGroup.length === 0,
                            emptyMsg: group.emptyMsg,
                        },
                        ...optionsFromGroup,
                    ];
                }
                return null;
            });
            const temporaryOption = this.options.find((option) => option?.temporary);
            const groupedOptions = groupWithOptions.filter((group) => group).flat();
            return temporaryOption ? [temporaryOption, ...groupedOptions] : groupedOptions;
        },
    },

    watch: {
        opened(newValue) {
            if (!newValue) {
                this.close();
            } else {
                setTimeout(() => {
                    const mobileInput = this.$refs.vselect?.$el?.querySelector('.vs__search');
                    mobileInput?.focus();
                }, 20);
            }
            this.$emit(newValue ? 'open' : 'close');
        },
        selectedOption(newVal) {
            if (newVal === null) {
                this.$emit('clear');
            }
        },
        modelValue(newVal) {
            if (this.editableInput) this.updateInputValue(newVal);
            this.setSelectedOption(newVal);
        },
        disabled(newValue) {
            if (!newValue) {
                this.close();
            }
        },
        options: {
            deep: true,
            async handler() {
                this.setSelectedOption(this.modelValue);
                await this.$nextTick();
                this.overlayOptionsRenderCount += 1;
            },
        },
    },

    mounted() {
        this.setSelectedOption(this.modelValue);
        if (this.editableInput) this.updateInputValue(this.modelValue);
    },

    methods: {
        getId: IdGenerator.get,
        reset() {
            this.$refs.vselect.clearSelection();
        },
        select(option) {
            this.$refs.vselect.select(option);
        },
        setSelectedOption(value) {
            /* eslint-disable eqeqeq */
            this.selectedOption =
                this.options.find((opt) => (this.valueField ? opt[this.valueField] === value : opt == value)) || value;
            /* eslint-enable eqeqeq */
            this.$nextTick(() => {
                const clearBtn = this.$refs.vselect.$el?.querySelector('.vs__clear');
                if (!this.clearBtnTestId || clearBtn?.hasAttribute('data-testid')) return;
                clearBtn?.setAttribute('data-testid', this.clearBtnTestId);
            });
        },
        getMenuItemsCount() {
            if (this.options) {
                return this.options.length;
            }
            return 0;
        },
        selectOption(option) {
            this.selectedOption = option;
            const val = this.selectedOption && this.valueField ? option[this.valueField] : option;
            this.$emit('input', val);
            this.$emit('update:modelValue', val);
            this.close();
        },
        close() {
            if (!this.opened) {
                return;
            }
            this.opened = false;
            this.$refs.vselect.closeSearchOptions();
        },
        onSearch(val) {
            this.$emit('search', val);
            this.overlayOptionsRenderCount += 1;
        },
        updateInputValue(value) {
            if (typeof value === 'string') {
                nextTick(() => {
                    this.$refs.vselect.search = value;
                });
            }
        },
        async focusInput(event) {
            if (event && event?.target.closest('.vs__clear')) {
                return;
            }
            if (this.showOverlay && !this.opened) {
                this.opened = true;
                await this.$nextTick();
            }
            this.$refs.input?.focus();
        },
        isOptionSelectable(option) {
            return !option.isGroup;
        },
        iconClick() {
            this.$emit('icon-click');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../../sass/scrollbars';

.selector {
    @apply border-slate-100;

    // shared appearance styles
    :deep() {
        .v-select {
            @apply border-slate-100;
        }

        .vs__dropdown-option {
            @apply whitespace-normal text-slate-600 p-0 pr-6;
            &:not(:last-child) {
                @apply border-solid border-slate-100 border-b;
            }
            &:hover {
                @apply bg-blue-100;
            }
            &:active,
            &:focus,
            &.vs__dropdown-option--highlight {
                @apply bg-blue-200;
            }
        }

        .vs__dropdown-menu {
            z-index: 10;
            min-width: var(--selector-options-width);
        }

        .vs__selected-options {
            @apply relative p-0 flex-nowrap overflow-hidden;
        }

        .vs__selected,
        .vs--open .vs__selected {
            @apply w-full m-0 p-0 border-0 h-full;
        }

        .vs__actions {
            @apply p-0 m-0;
        }
        .vs__clear {
            @apply mr-3;
        }

        .vs__search {
            @apply m-0 p-0 border-0 outline-none rounded-none placeholder:text-slate-400;
        }

        /*Need this to get rid of the clear search button in chrome which appears whatever searchable prop you use*/
        .vs__search::-webkit-search-cancel-button {
            display: none;
        }
    }

    &.icon-only {
        min-width: 5.5rem;
    }

    .clearable {
        :deep() {
            .vs__selected-options .selected-option-text {
                @apply pr-3;
            }
        }
    }

    &.default {
        :deep() {
            .vs__dropdown-toggle {
                @apply relative border-2 p-3 bg-slate-50;
                border-color: inherit;
                border-radius: inherit;

                &:hover {
                    @apply border-blue-300;
                }
            }

            .vs__selected-options {
                @apply mr-9;
            }

            .vs__dropdown-option--disabled {
                background-color: transparent;
            }

            .vs__actions {
                //TODO: design specifies h-6, but our inputs have line-height h-7. We need to refactor the line-height first
                @apply absolute h-7 min-w-6 flex justify-center items-center;
                right: 1rem;
            }

            .vs__dropdown-menu {
                @extend .custom-scrollbar;
                @apply border-blue-600 border-2 shadow-none p-0 mb-4 overflow-y-auto;
                border-top-style: solid !important;
                margin-top: -1px;
                max-height: var(--max-height);
                z-index: 5;

                &:last-child {
                    border-bottom-left-radius: inherit;
                    border-bottom-right-radius: inherit;
                }
            }

            .vs--disabled {
                .vs__dropdown-toggle {
                    @apply bg-slate-200 border-slate-400;
                    cursor: not-allowed;
                }

                .vs__search {
                    @apply bg-transparent;
                }

                .vs__actions {
                    @apply text-slate-400 bg-transparent;
                }

                .vs__open-indicator {
                    @apply bg-transparent;
                }
            }

            input,
            .vs__search,
            .vs__selected {
                line-height: inherit;
            }

            input:focus {
                margin: 0;
                padding: 0;
                border: 0;
            }

            .vs--open {
                .vs__dropdown-toggle {
                    @apply bg-white border-blue-600 rounded-b-none border-2 border-b-slate-100;
                    z-index: 6;
                }

                .vs__open-indicator.no-rotate {
                    transform: none;
                }

                .selected-option-text {
                    @apply truncate;
                }
            }
        }

        &.slim:not(.overlayed) {
            :deep() {
                .vs__dropdown-toggle {
                    @apply py-2;
                }
            }
        }

        &.checked :deep(.v-select:not(.vs--open) .vs__dropdown-toggle) {
            @apply border-green-200 bg-green-100 bg-opacity-25 border-2;
        }

        &.error :deep(.v-select:not(.vs--open) .vs__dropdown-toggle) {
            @apply border-red-600 bg-red-50 border-2;
        }

        &.empty-dropdown,
        &.overlayed {
            :deep() {
                .vs--open .vs__dropdown-toggle {
                    @apply border-blue-600;
                }
            }
        }
    }

    // menu appearance, this should be removed and the Button Menu be used instead
    &.menu {
        :deep() {
            .vs__dropdown-toggle {
                @apply py-1 px-3 border border-solid border-slate-100;
                border-radius: inherit;

                &:hover {
                    @apply border-slate-200;
                }
            }

            .vs__dropdown-option {
                @apply border-0;
                &:not(:last-child) {
                    @apply border-b;
                }
            }

            .vs__selected {
                @apply pr-2;
                position: static !important;
            }

            .vs__dropdown-menu,
            .vs--open .vs__dropdown-menu {
                @apply shadow-md bg-white text-black mt-2 border-0 p-0;
                -ms-overflow-style: none; /* Internet Explorer 10+ */
                scrollbar-width: none; /* Firefox */
                border-radius: inherit;
            }

            .vs__actions {
                transform: scale(0.75);
            }
        }
    }

    &.empty-dropdown,
    &.overlayed {
        :deep() {
            /* hide menu when overlayed or empty */
            .vs__dropdown-menu {
                display: none;
            }
            /* and as we have a standalone input again, round all borders */
            .vs--open .vs__dropdown-toggle {
                border-radius: inherit;
            }
        }
    }

    &.custom-background :deep(.v-select:not(.vs--open) .vs__dropdown-toggle),
    &.custom-background.menu :deep(.vs__dropdown-toggle) {
        @apply border-transparent border-2;
        background-color: inherit;
    }
}

.selector-overlay {
    @apply block h-full w-screen fixed top-0 right-0 left-0 bottom-0 z-40;
    &.default,
    &.menu {
        @screen md {
            @apply hidden;
        }
        .backdrop {
            @apply bg-black opacity-40;
        }
        .overlay-menu {
            max-height: 80vh;
        }
    }
}

.overlay-menu {
    li {
        @apply container py-5 flex flex-row items-center bg-white border-b border-solid border-slate-200 text-slate-600;
        div {
            @apply break-words max-w-full;
        }
        &:first-child {
            @apply border-t;
        }
        &:last-child {
            @apply border-b-0;
        }
        &:hover {
            @apply bg-blue-100;
        }
        &:active,
        &:focus,
        &.active {
            @apply bg-blue-200;
        }
        &.header {
            @apply bg-slate-50 text-slate-400 uppercase text-sm pl-5 pt-4 pb-3;
        }
    }
}
</style>
