<template>
    <Select
        ref="select"
        v-bind="$attrs"
        :options="options"
        :option-groups="showHeaders ? optionGroups : undefined"
        group-by="contextId"
        :loading="isLoading"
        open-loading
        :sort="sort"
        clearable
        searchable
        label-field="name"
        @input="onInput"
        @search="onSearch"
        @close="$emit('close')"
    >
        <template #option="{ option }">
            <div
                v-if="option?.temporary"
                class="px-3 py-3 leading-6 text-base truncate"
                @click="$emit('temporary-option-selected')"
                v-text="$t('search.searchBar.searchFor', { text: option.name })"
            ></div>
            <div
                v-else
                :class="['px-3 py-3 leading-6 text-base', truncate ? 'truncate' : '', optionColor]"
            >
                <span v-html="option.highlight?.name ? renderHighlightedName(option.highlight?.name[0]) : option.name">
                </span>
                <span v-if="option.university_name">
                    (
                    <span
                        v-html="
                            option.highlight?.university_name
                                ? renderHighlightedName(option.highlight?.university_name[0])
                                : option.university_name
                        "
                    />
                    )
                </span>
            </div>
        </template>

        <template
            v-if="keyword"
            #no-options
        >
            <div
                class="px-3 py-4 text-slate-400 leading-5 text-base text-justify"
                v-text="emptyResults || $t('common:search.noResults')"
            />
        </template>
    </Select>
</template>

<script lang="ts">
import { defineComponent, type VNode, type PropType } from 'vue';
import { mapGetters } from 'vuex';
import SearchMixin from '@/components/Select/SearchMixin';
import { search } from '@/api/gateway/legacy-api/search';
import { removeFromArray } from '@/helpers/array';
import type { EnumToUnion } from '@/types/misc';
import type { SearchContext } from './SearchElastic';

type GroupedOptions = Record<SearchContext, undefined | Array<Record<string, unknown>>>;

/**
 * Extends SearchMixin
 */
export default defineComponent({
    name: 'SearchElastic',
    extends: SearchMixin,
    inheritAttrs: false,
    props: {
        /**
         * Translation for empty results message
         */
        emptyResults: {
            type: String,
            default: '',
        },
        /**
         * Add headers
         */
        showHeaders: {
            type: Boolean,
            default: false,
        },
        /**
         * Context for the search
         */
        searchContext: {
            type: Array as PropType<Array<EnumToUnion<SearchContext>>>,
            default: () => [],
        },
        /**
         * Maximum number of results to display including all context
         */
        maxResults: {
            type: Number,
            default: 10,
        },
        /**
         * The filters to apply on the search. Filters vary depending on the context.
         */
        filters: {
            type: Object as PropType<Record<string, boolean | number | string | [] | undefined>>,
            default: () => ({}),
        },
        /**
         * Sorting for all contexts
         */
        sort: {
            type: String,
            default: 'best_match',
        },
        /**
         * Cache initial results
         */
        cacheInitialResults: {
            type: Boolean,
            default: false,
        },
        /**
         * Dropdown option default color
         */
        optionColor: {
            type: String,
            default: 'text-slate-400',
        },

        /**
         * truncate flag for the options
         */
        truncate: {
            type: Boolean,
            default: false,
        },
        /**
         * Searched query as first option
         */
        queryAsFirstOption: {
            type: Boolean,
            default: false,
        },
        /**
         * Hide the temporary option if exact match is found
         */
        tempOptionAlways: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'close',
        'input',
        'temporary-option-selected',
    ],
    data() {
        return {
            groupedOptions: {} as GroupedOptions,
            initialResults: [] as unknown[],
        };
    },
    computed: {
        ...mapGetters({
            screen: 'ui/screen',
        }),
        optionGroups() {
            return this.searchContext.map((item) => ({ id: item, label: this.$t(`common:search.context.${item}`) }));
        },
    },
    watch: {
        keyword(phrase) {
            if (!phrase && this.cacheInitialResults && this.initialResults.length) {
                // resets options to initialResults when keyword is cleared
                this.options = this.initialResults;
            }
        },
        groupedOptions: {
            // TODO: change to level 1 for vue version 3.5+
            deep: true,
            handler(groups: GroupedOptions) {
                let nextGroupSize = Math.ceil(this.maxResults / this.searchContext.length);

                // assign options from groups and distribute them so we have around maxResults in total
                this.options = this.searchContext
                    .map((context, i) => {
                        const options = groups[context]?.slice(0, nextGroupSize) ?? [];

                        nextGroupSize = Math.floor(
                            (this.maxResults - options.length) / (this.searchContext.length - i - 1),
                        );

                        return options;
                    })
                    .flat();

                if (this.cacheInitialResults && !this.initialResults.length) {
                    // checks whether all groups have values, as we might have multiple requests.
                    if (Object.values<undefined | unknown[]>(groups).every((options) => !!options?.length)) {
                        this.initialResults = this.options;
                    }
                }

                if (this.keyword && this.queryAsFirstOption) {
                    const keywordExists =
                        !this.tempOptionAlways &&
                        (this.options as Array<{ name: string }>).some(
                            (option) =>
                                typeof option.name === 'string' &&
                                option.name.toLowerCase() === this.keyword?.toLowerCase(),
                        );
                    if (!keywordExists) {
                        this.options.push({
                            name: this.keyword,
                            temporary: true,
                        });
                    }
                }
            },
        },
    },
    methods: {
        doSearch(keyword?: string) {
            this.keyword = keyword ?? null;

            this.searchContext.forEach((context) => {
                this.loadingCount += 1;
                const req = search(context, {
                    term: keyword,
                    highlight: true,
                    per_page: this.maxResults,
                    sort: this.sort,
                    ...this.filters,
                });
                this.requests.push(req);

                return req
                    .then((response) => {
                        // abort for empty data or already cleared keywords
                        if (!response?.data || (!this.keyword && this.keyword !== keyword)) return;

                        const results = response?.data;
                        const contextResults = results[context]?.results ?? results[context];
                        this.groupedOptions[context] = contextResults.map((option) => ({
                            ...option,
                            contextId: context,
                        }));
                    })
                    .finally(() => {
                        this.requests = removeFromArray(req, this.requests);
                        this.loadingCount -= 1;
                    });
            });
        },
        focusInput() {
            const selectElement = this.$refs.select as VNode & { focusInput: () => void };
            selectElement.focusInput();
        },
        renderHighlightedName(name: string) {
            const boldName = name.replace(/<em>/g, '<span class="text-slate-600">').replace(/<\/em>/g, '</span>');
            return boldName;
        },
    },
});
</script>
