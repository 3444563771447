import { transform, isObject, merge } from 'lodash';
import themes from './tailwind.colors';

/**
 * Flattens a theme color object into a single-level key-value structure
 * excludes 'on' and 'line-on' keys
 */
function flattenAndFilterColors(obj: Record<string, string | Record<string, string>>, prefix = '') {
    return transform(
        obj,
        (result, value, key) => {
            if (key === 'on' || key === 'line-on') return;
            const newKey = prefix ? `${prefix}-${key}` : key;

            merge(result, isObject(value) ? flattenAndFilterColors(value, newKey) : { [newKey]: value });
        },
        {},
    );
}

export const flatColors = flattenAndFilterColors(themes.light);
