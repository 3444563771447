import type { defineComponent } from 'vue';
import { register } from '@builder.io/sdk-vue';
import { flatColors } from '../../../tailwind.utils';
import BtnCta from '../components/BtnCta';
import Btn, { BtnContentSpacing, BtnLabelAlignment, BtnSize, BtnType, BtnVariant } from '../components/Btn';
import Link from '../components/Link';
import SearchGlobalHero from '../components/SearchGlobalHero';

const colorTokens = Object.entries(flatColors).map(([name, value]) => ({ name, value }));

register('editor.settings', {
    designTokens: {
        fontFamily: [
            { name: 'Primary Font (Roboto)', value: 'Roboto, sans-serif' },
            { name: 'Secondary Font (Work Sans)', value: 'Work Sans, sans-serif' },
        ],
        colors: colorTokens,
    },
});

type BuilderInput = {
    name: string;
    type?: string;
    defaultValue?: string;
    enum?: string[];
};

type BuilderComponent = [
    component: ReturnType<typeof defineComponent>,
    config: {
        childComponents?: Array<ReturnType<typeof defineComponent>>;
        docsLink?: string;
        inputs?: BuilderInput[];
    },
];

function mapVueTypeToBuilderType(propType: string): string {
    if (!propType) return 'string';

    const lowerType = propType.toLowerCase();
    return lowerType === 'array' ? 'list' : lowerType;
}

function mapComponentPropsToBuilderInputs(component: ReturnType<typeof defineComponent>): BuilderInput[] {
    const { props } = component;
    if (!props) return [];

    return Object.keys(props).map((propName) => {
        const prop = props[propName];

        return {
            name: propName,
            type: mapVueTypeToBuilderType(prop.type.name),
            defaultValue: prop.default,
        };
    });
}

// Helper function to merge inputs. Latest inputs override previous (last wins).
function mergeInputs(inputs: BuilderInput[]): BuilderInput[] {
    const merged = new Map<string, BuilderInput>();

    inputs.forEach((input) => {
        merged.set(input.name, {
            ...merged.get(input.name),
            ...input,
        });
    });

    return Array.from(merged.values());
}

const componentsToRegister: BuilderComponent[] = [
    [
        Btn,
        {
            childComponents: [Link],
            docsLink: 'https://storybook.studydrive.net/?path=/story/components-buttons-btn--docs',
            inputs: [
                {
                    name: 'label',
                    defaultValue: 'Button',
                },
                {
                    name: 'variant',
                    enum: Object.values(BtnVariant),
                },
                {
                    name: 'type',
                    enum: Object.values(BtnType),
                },
                {
                    name: 'size',
                    enum: Object.values(BtnSize),
                },
                {
                    name: 'contentSpacing',
                    enum: Object.values(BtnContentSpacing),
                },
                {
                    name: 'labelAlign',
                    enum: Object.values(BtnLabelAlignment),
                },
                {
                    name: 'href',
                    type: 'url',
                },
                {
                    name: 'color',
                    type: 'color',
                    enum: Object.keys(flatColors),
                },
                {
                    name: 'contentColor',
                    type: 'color',
                    enum: Object.keys(flatColors),
                },
                {
                    name: 'activeColor',
                    type: 'color',
                    enum: Object.keys(flatColors),
                },
                {
                    name: 'activeContentColor',
                    type: 'color',
                    enum: Object.keys(flatColors),
                },
            ],
        },
    ],
    [
        BtnCta,
        {
            childComponents: [Btn, Link],
            docsLink: 'https://storybook.studydrive.net/?path=/story/components-buttons-btncta--docs',
        },
    ],
    [SearchGlobalHero, {}],
];

function registerBuilderComponent(
    component: ReturnType<typeof defineComponent>,
    config: { childComponents?: Array<ReturnType<typeof defineComponent>>; docsLink?: string; inputs?: BuilderInput[] },
) {
    const childInputs: BuilderInput[] =
        config.childComponents?.flatMap((child): BuilderInput[] => {
            const childRegistration = componentsToRegister.find(([c]) => c === child);
            if (!childRegistration) {
                return mapComponentPropsToBuilderInputs(child);
            }
            const [childComponent, childConfig] = childRegistration;

            return [
                ...mapComponentPropsToBuilderInputs(child),
                ...mapComponentPropsToBuilderInputs(childComponent),
                ...(childConfig.inputs || []),
            ];
        }) ?? [];

    return {
        component,
        name: component.name,
        inputs: mergeInputs([
            ...(childInputs || []),
            ...mapComponentPropsToBuilderInputs(component),
            ...(config.inputs || []),
        ]),
        defaultStyles: {
            display: 'block',
        },
        docsLink: config.docsLink,
    };
}

export const registeredComponents = componentsToRegister.map(([component, config]) =>
    registerBuilderComponent(component, config),
);
