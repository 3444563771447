import type { Plugin } from 'vue';
import {
    init,
    captureMessage,
    replayIntegration,
    browserTracingIntegration,
    vueIntegration,
    type ErrorEvent,
} from '@sentry/vue';
import router from '@/router';
import eventProcessors from './eventProcessors';

export default {
    install(app) {
        if (import.meta.env.MIX_ENABLE_SENTRY === 'true') {
            const processors = Object.values(eventProcessors);

            init({
                app,
                dsn: import.meta.env.MIX_SENTRY_DSN,
                environment: import.meta.env.MIX_SENTRY_ENVIRONMENT,
                integrations: [
                    browserTracingIntegration({
                        router,
                    }),
                    replayIntegration(),
                    vueIntegration({
                        tracingOptions: {
                            trackComponents: true,
                        },
                    }),
                ],
                tracesSampleRate: parseFloat(import.meta.env.MIX_SENTRY_TRACES_SAMPLE_RATE || '1.0'),
                sampleRate: parseFloat(import.meta.env.MIX_SENTRY_SAMPLE_RATE || '1.0'),
                tracePropagationTargets: ['localhost', import.meta.env.APP_URL as string],
                replaysSessionSampleRate: parseFloat(import.meta.env.MIX_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || '0'),
                replaysOnErrorSampleRate: parseFloat(import.meta.env.MIX_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || '1.0'),
                denyUrls: [
                    // Chrome extensions
                    /extensions\//i,
                    /^chrome:\/\//i,
                    // Safari extensions
                    /safari-(web-)?extension:/,
                    // UserCentrics packages
                    /https?:\/\/(.+\.)?usercentrics\.eu/,
                ],
                ignoreErrors: [
                    // ignore error related with Google Sign-in in incognito pages because Google Sign-in is currently not supported in incognito mode on Chrome on iOS
                    /^Abgebrochen$/,

                    // ignore errors originating from the AS adLib
                    /^undefined is not an object \(evaluating 'l.hasAd'\)/,
                    /^Cannot read properties of undefined \(reading 'sizes'\)/,
                    /^undefined is not an object \(evaluating 'g.activeOverlay=""'\)/,
                    /^Cannot set properties of undefined \(setting 'loadAttempts'\)/,
                    /^undefined is not an object \(evaluating 's.sizeMapping\[0].sizes'\)/,
                    /^Cannot read properties of undefined \(reading 'gamSlot'\)/,
                    /^undefined is not an object \(evaluating ['"][A-Za-z]\.loadAttempts=0['"]\)/,
                    /^apntag.clearRequest is not a function/,
                    /^Cannot read properties of undefined \(reading 'adSlot'\)/,
                    /^apntag\.clearRequest is not a function\. \(In 'apntag\.clearRequest\(\)', 'apntag\.clearRequest' is undefined\)/,
                    /^Cannot read properties of null \(reading 'setAttribute'\)/,
                    /^n.adSlot is null/,
                    /^document\.getElementsByTagName\(...\)\[0] is undefined/,
                    /^[A-Za-z] is undefined$/,
                    /^undefined is not an object \(evaluating ['"][A-Za-z]\.gamSlot['"]\)/,
                    /^illegal character U\+FFFD/,
                    /^Can't find variable: BigInt$/,
                    /^Cannot read properties of null \(reading 'style'\)/,
                    /^Cannot read properties of undefined \(reading 'adSlot'\)/,
                    /^null is not an object \(evaluating 'i\.contentWindow\.document'\)/,
                    /^Cannot set properties of undefined \(setting 'advertiserId'\)/,
                    /^undefined is not an object \(evaluating ['"][A-Za-z]\.advertiserId=["']{2}['"]\)/,
                    /^Cannot set property ['"]advertiserId['"] of undefined/,
                    /^Cannot read property ['"]adSlot['"] of undefined/,

                    // ignore error related to bad connection/timeout
                    /^timeout of 0ms exceeded$/,
                ],
                /* this will invoke every event processor defined in ./eventProcessors/index.js */
                beforeSend(event, hint) {
                    let processedEvent: ErrorEvent | null = event;
                    for (const processor of processors) {
                        processedEvent = processor(processedEvent, hint);

                        if (!processedEvent) return null;
                    }

                    return processedEvent;
                },
            });

            // eslint-disable-next-line no-param-reassign
            app.config.warnHandler = (msg) => {
                captureMessage(msg, 'warning');
            };
        }
    },
} as Plugin;
