<template>
    <div class="transition-roll">
        <transition-group
            name="roll"
            class="grid"
            tag="div"
        >
            <template
                v-for="(item, index) in items"
                :key="index"
            >
                <div v-show="currIndex === index">
                    <!-- 
                        @slot customize item appearance
                        @binding {object} item - The item to display
                    -->
                    <slot
                        :item="item"
                        name="item"
                        >{{ item }}</slot
                    >
                </div>
            </template>
        </transition-group>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref } from 'vue';
import { useIntervalFn } from '@vueuse/core';

/**
 * Transition that rolls through the provided list of elements
 */
export default defineComponent({
    name: 'TransitionRoll',
    props: {
        /**
         * The interval in milliseconds between each transition.
         */
        interval: {
            type: Number,
            default: 3000,
        },
        /**
         * The items to loop through.
         */
        items: {
            type: Array as PropType<Array<string | unknown>>,
            required: true,
        },
    },
    setup(props) {
        const currIndex = ref(0);

        useIntervalFn(() => {
            currIndex.value = (currIndex.value + 1) % props.items.length;
        }, props.interval);

        return {
            currIndex,
        };
    },
});
</script>

<style lang="scss" scoped>
@keyframes roll-enter {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    50% {
        transform: translateY(-15%);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.transition-roll {
    display: inline-block;
    min-height: 1em;
}

.roll-enter-active,
.roll-leave-active {
    grid-area: 1 / 1 / 2 / 2;
}

.roll-enter-active {
    animation: roll-enter 0.3s linear;
}

.roll-leave-active {
    transition: all 0.15s linear;
}
.roll-leave-from {
    transform: translateY(0);
    opacity: 1;
}

.roll-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}
</style>
