<template>
    <div :class="'skeleton-' + appearance">
        <ul v-if="appearance === 'sidebar'">
            <li class="skeleton-wrapper w-3/4">
                <div class="skeleton-bone"></div>
            </li>
            <li class="skeleton-wrapper w-1/3">
                <div class="skeleton-bone"></div>
            </li>
            <li class="skeleton-wrapper w-1/2">
                <div class="skeleton-bone"></div>
            </li>
            <li class="skeleton-wrapper w-1/4">
                <div class="skeleton-bone"></div>
            </li>
        </ul>
        <ul
            v-else-if="appearance === 'category-group-list'"
            class="skeleton-wrapper"
        >
            <li class="skeleton-bone bone-sm"></li>
            <li class="skeleton-bone bone-md"></li>
            <li class="skeleton-bone bone-lg"></li>
            <li class="skeleton-bone bone-md"></li>
        </ul>
        <span
            v-else-if="appearance === 'tab'"
            class="skeleton-wrapper"
        >
            <div class="skeleton-bone"></div>
            <div class="skeleton-bone bone-sm"></div>
        </span>
        <span
            v-else-if="appearance === 'challenges'"
            class="skeleton-wrapper"
        >
            <div class="flex flex-wrap w-full md:mb-14 lg:mb-4">
                <div class="w-full flex flex-col pt-6">
                    <div class="w-2/6 md:w-1/5">
                        <div class="skeleton-bone bone-sm"></div>
                    </div>
                    <div class="w-3/6 md:w-2/5">
                        <div class="skeleton-bone bone-md"></div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col md:flex-row w-full md:justify-between lg:justify-start">
                <div class="flex flex-wrap rounded-lg w-full md:w-80 lg:w-5/12 mr-7">
                    <div class="skeleton-bone bone-block"></div>
                </div>
                <div class="flex flex-wrap rounded-lg w-full md:w-80 lg:w-5/12">
                    <div class="skeleton-bone bone-block"></div>
                </div>
            </div>
        </span>
        <span
            v-else-if="appearance === 'group-list'"
            class="skeleton-wrapper"
        >
            <div class="shadow rounded-lg flex flex-col bg-white h-full w-full">
                <div class="w-full flex flex-col h-full">
                    <div class="w-full rounded-t-lg skeleton-bone bone-block"></div>
                    <div class="p-4 flex flex-col justify-between h-full">
                        <div class="mt-2">
                            <div>
                                <div class="skeleton-bone bone-md"></div>
                            </div>
                            <div>
                                <div class="skeleton-bone bone-sm mt-1"></div>
                            </div>
                        </div>
                        <div class="skeleton-bone bone-lg"></div>
                    </div>
                </div>
            </div>
        </span>
        <template v-else-if="appearance === 'text' || appearance === 'card'">
            &nbsp;
            <!--this is needed -->
            <div></div>
        </template>
    </div>
</template>

<script>
export const TYPES = {
    TEXT: 'text',
    RECT: 'rect',
    TAB: 'tab',
    CARD: 'card',

    // DEPRECATED, as too specific
    SIDEBAR: 'sidebar',
    CHALLENGES: 'challenges',
    GROUP_LIST: 'group-list',
    CATEGORY_GROUP_LIST: 'category-group-list',
};

export default {
    name: 'Skeleton',
    props: {
        /**
         * @TODO make it more generic and put specific skeletons in their own component.
         * @IMPORTANT only declare re-usable appearances here, specific designs (like sidebar) should rather
         * be directly composed by re-usable skeletons in the respective component file.
         */
        appearance: {
            type: String,
            required: false,
            default: 'sidebar',
            validator: (value) => Object.values(TYPES).includes(value),
        },
    },
};
</script>

<style lang="scss" scoped>
// extend this helper to add skeleton styling to your class
%skeleton-item {
    @apply relative w-full rounded bg-slate-100 m-0 p-0 overflow-hidden;
    &:before {
        @apply absolute w-full h-full pointer-events-none opacity-75 top-0 left-0;
        transform: translateX(-100%);
        content: '';
        background: linear-gradient(0.25turn, transparent, theme('colors.white'), transparent);
        animation: skeleton-animation 2s infinite;
    }
}

.skeleton {
    &-text,
    &-card {
        @apply relative flex items-center;

        > div {
            left: 0;
            height: 1em;
            width: 100%;
            @extend %skeleton-item;
            @apply absolute;
        }
    }

    &-card {
        > div {
            height: 100%;
            @apply rounded-lg;
        }
    }

    &-rect {
        @extend %skeleton-item;
    }

    &-sidebar {
        @apply overflow-hidden block relative;
        .skeleton-wrapper {
            @apply h-full pt-3 pb-4 ml-2 mr-2;
            .skeleton-bone {
                height: 18px;
                @extend %skeleton-item;
            }
        }
    }

    &-category-group-list {
        @apply overflow-hidden block relative;
        .skeleton-wrapper {
            @apply flex flex-row items-start px-0 h-full;

            .skeleton-bone {
                @extend %skeleton-item;
                @apply h-13 mr-2;

                @screen md {
                    @apply h-11;
                }
                &:last-child {
                    @apply mr-0;
                }
                &.bone-sm {
                    @apply w-20;
                }
                &.bone-md {
                    @apply w-32;
                }
                &.bone-lg {
                    @apply w-36;
                }
            }
        }
    }

    &-tab {
        @apply overflow-hidden flex relative;
        width: 140px;

        .skeleton-wrapper {
            @apply h-full flex flex-col items-center mt-2 w-full;
            .skeleton-bone {
                @extend %skeleton-item;
                @apply inline-block w-full;
                height: 16px;

                &.bone-sm {
                    @apply h-1 mt-3;
                }
            }
        }
    }

    &-challenges {
        @apply overflow-hidden flex relative w-full;
        .skeleton-wrapper {
            @apply h-full flex flex-wrap w-full px-7;
            @screen md {
                @apply px-0;
            }
            .skeleton-bone {
                @extend %skeleton-item;
                @apply inline-block;

                &.bone-sm {
                    @apply h-6;
                }

                &.bone-md {
                    @apply h-12;
                    @screen md {
                        @apply h-8;
                    }
                }

                &.bone-block {
                    @apply h-28;
                    &:last-of-type {
                        @apply mt-4;
                        @screen md {
                            @apply mt-0;
                        }
                    }

                    @screen md {
                        height: 174px;
                    }
                    @screen lg {
                        @apply h-40 mt-0;
                    }
                }
            }
        }
    }

    &-group-list {
        @apply overflow-hidden flex relative;
        .skeleton-wrapper {
            height: 18rem;
            @apply flex flex-wrap w-full w-full  mb-8;
            @screen md {
                @apply w-1/2;
            }
            @screen lg {
                @apply px-0 w-1/3;
            }
            &:first-child {
                @apply pr-0;
                @screen md {
                    @apply pr-4;
                }
                @screen lg {
                    @apply pr-2;
                }
            }
            .skeleton-bone {
                @extend %skeleton-item;
                @apply inline-block rounded-lg;

                &.bone-block {
                    @apply h-6 w-full rounded-b-none;
                    min-height: 120px;
                }

                &.bone-sm {
                    @apply h-5 w-1/3;
                }

                &.bone-md {
                    @apply h-6 w-1/2;
                }

                &.bone-lg {
                    @apply h-12 py-4 px-6;
                    @screen md {
                        @apply h-12;
                    }
                }
            }
        }
    }
}

@keyframes skeleton-animation {
    0% {
        transform: translateX(-100%);
    }
    30% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
</style>
