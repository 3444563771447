export enum SearchContext {
    COURSES = 'courses',
    UNIVERSITIES = 'universities',
    SCHOOLS = 'schools',
    // don't forget to add translations ;)
}

export enum MaterialType {
    COURSE = 'course',
    UNIVERSITY = 'university',
    SCHOOL = 'school',
}

export const ContextMap = {
    [SearchContext.COURSES]: MaterialType.COURSE,
    [SearchContext.UNIVERSITIES]: MaterialType.UNIVERSITY,
    [SearchContext.SCHOOLS]: MaterialType.SCHOOL,
} as const;
