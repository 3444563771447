import { featureIsEnabled } from '@/modules/features';
import type { Operations, URLExtractor } from 'unpic';
import { createExtractAndGenerate, createOperationsHandlers, toCanonicalUrlString, toUrl } from 'unpic/utils';
// @ts-ignore cannot resolve type imports
import { transform as builderTransformer } from 'unpic/providers/builder.io';
import type { transform as BuilderTransform } from 'unpic/esm/src/providers/builder.io';

// Note: Read more about the cdn image service here: https://eureka.stepstone.com/display/STUD/Image+Optimization+Service

export type DisplayMode = 'inside' | 'cover' | 'contain' | 'fill' | 'outside';

/**
 * Allowed image oprations on the cdn
 */
export interface StudydriveCdnOperations extends Operations {
    w?: number | string;
    h?: number | string;
    f?: string;
    m?: DisplayMode;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface StudydriveCdnOptions {}

declare module 'unpic' {
    interface ProviderOperations {
        studydrive: StudydriveCdnOperations;
    }

    interface ProviderOptions {
        studydrive: StudydriveCdnOptions;
    }
}

// bigger breakpoints supported by the cdn
export const BREAKPOINTS = [
    32,
    40,
    48,
    64,
    80,
    96,
    128,
    192,
    256,
    384,
    512,
    768,
    1024,
    1440,
    2048,
    3072,
] as const;

const { operationsParser, operationsGenerator } = createOperationsHandlers<StudydriveCdnOperations>({
    // Map standard operation names to studydrive-cdn operations
    keyMap: {
        width: 'w',
        height: 'h',
        quality: false,
        format: 'f',
    },
    // Define parameter formatting
    kvSeparator: '=',
    paramSeparator: '&',
});

// Extract operations from existing URL
const extract: URLExtractor = (url) => {
    const parsedUrl = toUrl(url);
    // we don't need to parse non cdn img urls
    if (!/cdn.*studydrive\..+\/i\//.test(parsedUrl.hostname.toString())) return null;

    const operations = operationsParser(parsedUrl);
    parsedUrl.search = '';

    return {
        src: toCanonicalUrlString(parsedUrl),
        operations,
        options: {
            baseUrl: parsedUrl.origin,
        },
    };
};

// Generate new URL with operations
const generate = (src: string | URL, operations: StudydriveCdnOperations): string => {
    const url = toUrl(src);
    url.search = operationsGenerator(operations);

    // replace /d/ with /i/ to use the cdn image optimization endpoint
    url.pathname = url.pathname.replace(/^\/d\//, '/i/');
    return toCanonicalUrlString(url) as string;
};

// unpic studydrive cdn transformer
const studydriveTransformer: (src: string | URL, operations: StudydriveCdnOperations) => string =
    // @ts-ignore we are unable to overwrite the unpic ImageCdn options
    createExtractAndGenerate(extract, generate);

export function isStudyriveCdnFile(src: string) {
    return /cdn.*studydrive\./.test(src);
}

export function isBuilderCdnFile(src: string) {
    return src.includes('cdn.builder.io');
}

/**
 * Transforms cdn urls to an optimized image url.
 * Non-cdn urls are returned unchanged.
 */
export function transformUrl(src: string, operations: StudydriveCdnOperations) {
    if (!src) return src;

    // don't process non cdn urls
    if (featureIsEnabled('imageHandler') && isStudyriveCdnFile(src)) {
        return studydriveTransformer(src, operations);
    }

    if (isBuilderCdnFile(src)) return builderTransformer(src, operations) as typeof BuilderTransform;

    return src;
}
