<template>
    <div :class="['input-group', direction]">
        <slot></slot>
    </div>
</template>

<script>
export const DIRECTION = {
    ROW: 'row',
    COLUMN: 'column',
    NONE: null,
};

export default {
    name: 'InputGroup',
    props: {
        direction: {
            type: String,
            default: DIRECTION.ROW,
            validator: (value) => Object.values(DIRECTION).includes(value),
        },
    },
};
</script>

<style lang="scss" scoped>
@mixin adjustBorders($site, $oppositeSite) {
    &:not(:last-child) {
        /* remove default border (not hovered, focused, active) */
        &,
        input {
            &:not(:focus):not(:active):not(:hover) {
                &,
                .v-select:not(.vs--open) .vs__dropdown-toggle {
                    border-#{$site}-color: transparent;
                }
            }
        }

        /* if element gets special border, remove opposite border of next element */
        &:focus,
        &:active,
        &:hover {
            + {
                .selector,
                input,
                * input {
                    &:not(:focus):not(:active):not(:hover) {
                        &,
                        .v-select:not(.vs--open) .vs__dropdown-toggle {
                            border-#{$oppositeSite}-color: transparent;
                        }
                    }
                }
            }
        }
    }
}

.input-group {
    display: flex;

    &.row {
        :deep() > * {
            &:not(:first-child) {
                &,
                &.selector .vs__dropdown-toggle {
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                }
            }

            &:not(:last-child) {
                &,
                &.selector .vs__dropdown-toggle {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }
            }

            @include adjustBorders('right', 'left');
        }
    }

    &.column {
        flex-direction: column;

        :deep() > * {
            &:not(:first-child) {
                &,
                &.selector .vs__dropdown-toggle {
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                }
            }

            &:not(:last-child) {
                &,
                &.selector .vs__dropdown-toggle {
                    border-bottom-right-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                }
            }

            @include adjustBorders('bottom', 'top');
        }
    }
}
</style>
