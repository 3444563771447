import { merge, cloneDeep } from 'lodash-es';

type ColorValue = `#${string}`;
type Shade = 950 | 900 | 800 | 700 | 600 | 500 | 400 | 300 | 200 | 100 | 50;
type ColorShades<T = ColorValue> = Record<Shade, T>;
type ColorGroup<T = ColorValue> = Record<string, T> | ColorShades<T>;

function flipShades<T>(shades: ColorShades<T>) {
    const entries = Object.entries(shades) as unknown as Array<[Shade, T]>;
    const reversedValues = entries.map(([key]) => shades[key]).reverse();
    return Object.fromEntries(entries.map(([key], index) => [key, reversedValues[index]]));
}

const W = '#ffffff';
const B = '#000000';

type Colors = Record<string, ColorValue | ColorShades>;
const colors = {
    white: W,
    black: B,
    red: {
        950: '#450a0a',
        900: '#7f1d1d',
        800: '#991b1b',
        700: '#b91c1c',
        600: '#dc2626',
        500: '#ef4444',
        400: '#f87171',
        300: '#fca5a5',
        200: '#fecaca',
        100: '#fee2e2',
        50: '#fef2f2',
    },
    orange: {
        950: '#431407',
        900: '#7c2d12',
        800: '#9a3412',
        700: '#c2410c',
        600: '#ea580c',
        500: '#f97316',
        400: '#fb923c',
        300: '#fdba74',
        200: '#fed7aa',
        100: '#ffedd5',
        50: '#fff7ed',
    },
    yellow: {
        950: '#422006',
        900: '#713f12',
        800: '#854d0e',
        700: '#a16207',
        600: '#ca8a04',
        500: '#eab308',
        400: '#facc15',
        300: '#fde047',
        200: '#fef08a',
        100: '#fef9c3',
        50: '#fefce8',
    },
    green: {
        950: '#052e16',
        900: '#14532d',
        800: '#166534',
        700: '#15803d',
        600: '#16a34a',
        500: '#22c55e',
        400: '#4ade80',
        300: '#86efac',
        200: '#bbf7d0',
        100: '#dcfce7',
        50: '#f0fdf4',
    },
    blue: {
        950: '#011d32',
        900: '#022c4c',
        800: '#0d4e81',
        700: '#0a65b2',
        600: '#0691fb',
        500: '#38a7fc',
        400: '#6abdfd',
        300: '#afd6fe',
        200: '#cde9fe',
        100: '#eef7ff',
        50: '#f5fbff',
    },
    purple: {
        950: '#2e1065',
        900: '#4c1d95',
        800: '#5b21b6',
        700: '#6d28d9',
        600: '#7c3aed',
        500: '#8b5cf6',
        400: '#a78bfa',
        300: '#c4b5fd',
        200: '#ddd6fe',
        100: '#ede9fe',
        50: '#f5f3ff',
    },
    pink: {
        950: '#4a044e',
        900: '#701a75',
        800: '#86198f',
        700: '#a21caf',
        600: '#c026d3',
        500: '#d946ef',
        400: '#e879f9',
        300: '#f0abfc',
        200: '#f5d0fe',
        100: '#fae8ff',
        50: '#fdf4ff',
    },
    slate: {
        950: '#020617',
        900: '#0f172a',
        800: '#1e293b',
        700: '#334155',
        600: '#475569',
        500: '#64748b',
        400: '#94a3b8',
        300: '#cbd5e1',
        200: '#e2e8f0',
        100: '#f1f5f9',
        50: '#f8fafc',
    },
} as const satisfies Colors;

type ContrastValue = typeof W | typeof B;
type Contrasts = {
    [K in keyof typeof colors]: (typeof colors)[K] extends ColorValue ? ContrastValue : ColorShades<ContrastValue>;
};
// contrasts mirrors the structure of colors to ensure we have each value represented
const contrasts = {
    white: B,
    black: W,
    red: {
        950: W,
        900: W,
        800: W,
        700: W,
        600: W,
        500: W,
        400: B,
        300: B,
        200: B,
        100: B,
        50: B,
    },
    orange: {
        950: W,
        900: W,
        800: W,
        700: W,
        600: W,
        500: B,
        400: B,
        300: B,
        200: B,
        100: B,
        50: B,
    },
    yellow: {
        950: W,
        900: W,
        800: W,
        700: W,
        600: W,
        500: B,
        400: B,
        300: B,
        200: B,
        100: B,
        50: B,
    },
    green: {
        950: W,
        900: W,
        800: W,
        700: W,
        600: W,
        500: B,
        400: B,
        300: B,
        200: B,
        100: B,
        50: B,
    },
    blue: {
        950: W,
        900: W,
        800: W,
        700: W,
        600: W,
        500: B,
        400: B,
        300: B,
        200: B,
        100: B,
        50: B,
    },
    purple: {
        950: W,
        900: W,
        800: W,
        700: W,
        600: W,
        500: W,
        400: W,
        300: B,
        200: B,
        100: B,
        50: B,
    },
    pink: {
        950: W,
        900: W,
        800: W,
        700: W,
        600: W,
        500: W,
        400: B,
        300: B,
        200: B,
        100: B,
        50: B,
    },
    slate: {
        950: W,
        900: W,
        800: W,
        700: W,
        600: W,
        500: W,
        400: B,
        300: B,
        200: B,
        100: B,
        50: B,
    },
} satisfies Contrasts;

type Themes = 'light' | 'dark';
type ThemedColors = {
    [T in Themes]: {
        [K in string]: ColorValue | ColorGroup;
    } & {
        on?: {
            [K in keyof Omit<ThemedColors[T], 'on' | 'line-on'>]: ColorValue | ColorGroup;
        };
        'line-on'?: {
            [K in keyof Omit<ThemedColors[T], 'on' | 'line-on'>]: ColorValue | ColorGroup;
        };
    };
};

export const primitiveThemedColors = {
    light: {
        ...colors,
        on: contrasts,
    },
    dark: {
        white: B,
        black: W,
        red: flipShades(colors.red),
        orange: flipShades(colors.orange),
        yellow: flipShades(colors.yellow),
        green: flipShades(colors.green),
        blue: flipShades(colors.blue),
        purple: flipShades(colors.purple),
        pink: flipShades(colors.pink),
        slate: flipShades(colors.slate),
        on: {
            white: W,
            black: B,
            red: flipShades(contrasts.red),
            orange: flipShades(contrasts.orange),
            yellow: flipShades(contrasts.yellow),
            green: flipShades(contrasts.green),
            blue: flipShades(contrasts.blue),
            purple: flipShades(contrasts.purple),
            pink: flipShades(contrasts.pink),
            slate: flipShades(contrasts.slate),
        },
    },
};

export const semanticThemedColors: ThemedColors = {
    light: {
        primary: colors.blue[600],

        background: colors.slate[100],
        panel: '#F8FAFC',
        surface: W,

        negative: colors.red[600],
        positive: colors.green[600],

        brand: {
            whatsapp: '#25D366',
            facebook: '#1877F2',
            twitter: '#00ACEE',
        },

        content: {
            primary: colors.slate[950],
            secondary: colors.slate[600],
            tertiary: colors.slate[400],
        },

        disabled: colors.slate[400],
        overlay: `${B}66`, // using hex alpha values (40%)

        // default text colors ON specific backgrounds
        on: {
            primary: W,
            secondary: colors.slate[950],
            background: colors.slate[950],
            surface: colors.slate[950],
            negative: W,
            positive: W,
        },

        // default border colors ON specific backgrounds
        'line-on': {
            surface: '#F5F8FB',
            glassmorphic: W,
        },
    },
    dark: {
        primary: colors.blue[500],

        background: '#0E131B',
        panel: '#181D25',
        surface: '#2D3138',

        negative: colors.red[700],
        positive: colors.green[700],

        brand: {
            whatsapp: '#25D366',
            facebook: '#1877F2',
            twitter: '#00ACEE',
        },

        content: {
            primary: W,
            secondary: colors.slate[700],
            tertiary: colors.slate[600],
        },

        disabled: colors.slate[600],
        overlay: `${B}90`, // using hex alpha values (60%)

        // default text colors ON specific backgrounds
        on: {
            primary: W,
            secondary: B,
            background: W,
            surface: W,
            negative: B,
            positive: B,
        },

        'line-on': {
            surface: '#181D25',
            glassmorphic: '#21262E',
        },
    },
};

export default merge(cloneDeep(primitiveThemedColors), semanticThemedColors) satisfies ThemedColors;
