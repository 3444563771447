<template>
    <Menu
        ref="menu"
        :options="options"
        v-bind="menuAttrs"
        @update:shown="$emit('is-menu-opened', $event)"
        @click:option="$emit('click:option', $event)"
    >
        <template #default="{ shown }">
            <Btn
                v-tooltip="{
                    content: tooltipText,
                    disabled: !tooltipText,
                }"
                v-bind="btnAttrs"
                :active="shown"
            >
                <!-- forward all Btn slots -->
                <template
                    v-for="slot in slotGroups.btn"
                    #[slot]
                >
                    <slot :name="slot" />
                </template>
            </Btn>
        </template>

        <!-- forward all Menu slots -->
        <template
            v-for="slot of slotGroups.menu"
            #[slot]="slotProps"
        >
            <slot
                :name="slot"
                v-bind="slotProps"
            />
        </template>
    </Menu>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue';
import { pickBy, omit, groupBy } from 'lodash-es';
import type { MenuOption } from '@/components/Menu';

/**
 * This component is a wrapper around the Menu component. by default it passes all attributes from parent to
 * Btn component but it also passes all attributes starting with `menu-` to Menu component.
 */
export default defineComponent({
    name: 'BtnMenu',
    inheritAttrs: false,
    props: {
        /**
         * Array of menu options.
         */
        options: {
            type: Array as PropType<MenuOption[]>,
            default: () => [],
        },
        /**
         * Text to diplay in the tooltip.
         */
        tooltipText: {
            type: String,
            default: null,
        },
    },
    emits: ['is-menu-opened', 'click:option'],
    computed: {
        slotGroups() {
            return groupBy(Object.keys(this.$slots), (slot) => (slot.startsWith('menu') ? 'menu' : 'btn'));
        },
        menuAttrs(): Record<string, unknown> {
            return Object.fromEntries(
                Object.entries(this.$attrs)
                    .filter(([key]) => key.startsWith('menu-'))
                    .map(([key, value]) => [key.replace('menu-', ''), value]),
            );
        },
        btnAttrs(): Record<string, unknown> {
            const atr = omit(this.$attrs, ['component', 'component-props']);
            return pickBy(atr, (value, key) => !key.startsWith('menu-'));
        },
    },
});
</script>
