import AssetCdnImage from '@/components/AssetCdnImage';
import Btn from '@/components/Btn';
import BtnCta from '@/components/BtnCta';
import BtnLink from '@/components/BtnLink';
import BtnMenu from '@/components/BtnMenu';
import InputContainer from '@/components/InputContainer';
import InputField from '@/components/InputField';
import Link from '@/components/Link';
import Menu from '@/components/Menu';
import SearchElastic from '@/components/SearchElastic';
import Select from '@/components/Select';
import Skeleton from '@/components/Skeleton';
import Spinner from '@/components/Spinner';
import Tag from '@/components/Tag';
import TagList from '@/components/TagList';
import UserAvatar from '@/components/UserAvatar';

/**
 * Global resuable components
 */
export default {
    AssetCdnImage,
    Btn,
    BtnCta,
    BtnLink,
    BtnMenu,
    InputContainer,
    InputField,
    Link,
    Menu,
    SearchElastic,
    Select,
    Skeleton,
    Spinner,
    Tag,
    TagList,
    UserAvatar,
};
