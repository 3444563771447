<template>
    <li
        class="border-b border-bg-slate-100"
        style="cursor: pointer"
        @click="notificationClick"
    >
        <span
            :class="[
                {
                    'bg-slate-50 focus:bg-slate-100 hover:bg-slate-100 active:bg-slate-200': notification.read,
                    'bg-blue-100 focus:bg-blue-200 hover:bg-blue-200 active:bg-blue-300': !notification.read,
                },
                `flex m-1 shadow-inner-sm rounded-md text-slate-600`,
            ]"
        >
            <div class="flex-initial pt-3 pl-6 pb-12">
                <UserAvatar
                    :animated-avatar="notification.data.avatar"
                    :picture="notification.data.profile_picture"
                    size="comment"
                ></UserAvatar>
            </div>
            <div class="flex-1 pl-2 pt-3 pr-6 md:pr-3">
                <div
                    class="notification-text text-sm leading-normal"
                    v-html="formatMessage(notification.data.message)"
                ></div>
                <span :class="[notification.read ? 'text-slate-400' : 'text-blue-600', `text-xs pt-2`]">
                    {{ notification.data.human_time }}
                </span>
            </div>
        </span>
    </li>
</template>

<script>
import { mapActions } from 'vuex';
import { trackEvent } from '@/modules/tracking';

export default {
    name: 'NotificationCard',
    props: {
        notification: {
            type: Object,
            required: true,
            default() {
                return {
                    data: {},
                    notification_id: null,
                    read: true,
                    type: null,
                };
            },
        },
    },
    methods: {
        ...mapActions({
            setAsRead: 'notifications/setAsRead',
        }),
        async notificationClick() {
            trackEvent('header_notification', 'header_notification_click', this.notification.type);
            if (!this.notification.read) {
                await this.setAsRead(this.notification.notification_id);
            }

            window.location.href = this.notification.data.url;
        },
        formatMessage(message) {
            return message.replace(/(".*")/, '<span>$1</span>');
        },
    },
};
</script>

<style lang="scss" scoped>
.notification-text {
    :deep(span) {
        @apply font-bold;
    }
}
</style>
