<template>
    <component
        v-bind="$attrs"
        :is="$attrs.is ?? tag"
        :class="['btn ', ...buttonClasses]"
        :disabled="disabled || null"
        :aria-disabled="disabled || null"
        :style="buttonStyles"
        :type="isHtmlButton ? type : undefined"
    >
        <div class="btn-container">
            <div
                v-if="loading"
                class="loader-slot absolute inset-0 flex justify-center items-center"
            >
                <!-- @slot custom loader-->
                <slot name="loader">
                    <Spinner
                        appearance="circular"
                        color="none"
                        class="spinner"
                    />
                </slot>
            </div>

            <div :class="['content-slot w-full', { invisible: loading }]">
                <!-- @slot custom content (replaces icons & label)-->
                <slot name="content">
                    <div
                        class="btn-content"
                        :style="{ justifyContent }"
                    >
                        <!-- LEFT ICON -->
                        <div
                            v-if="hasLeftIconOrSlot"
                            class="icon-slot flex shrink-0"
                        >
                            <!-- @slot left slot to include custom icons, texts & elements -->
                            <slot name="left">
                                <i
                                    v-if="primaryIcon"
                                    :class="[`btn-icon icon icon-${primaryIcon}`]"
                                />
                            </slot>
                        </div>

                        <!-- LABEL -->
                        <transition :name="labelTransition ? 'btn-label' : ''">
                            <div
                                v-if="hasLabelOrSlot"
                                data-fe-test="btn-label"
                                :class="['label items-center min-w-0', labelFlex, labelAlignClass]"
                                :style="labelDirection"
                            >
                                <!-- some wrapping divs are needed to make the label on hover logic possible -->
                                <div :class="['w-full', labelSpacing]">
                                    <!--
                                        For the label reveal we need to know the full text length.
                                        To also have truncation, it's easiest to have separate elements.
                                        One that is truncated and the other providing the max text length
                                    -->
                                    <div
                                        v-if="hasLabelOnTouch"
                                        ref="label"
                                        v-mutation.immediate="onLabelChange"
                                        class="absolute invisible"
                                    >
                                        <slot>{{ label }}</slot>
                                    </div>

                                    <!-- @slot custom label -->
                                    <slot>{{ label }}</slot>
                                </div>
                            </div>
                        </transition>

                        <!-- RIGHT ICON -->
                        <div
                            v-if="hasRightIconOrSlot"
                            class="icon-slot flex shrink-0"
                        >
                            <!-- @slot right slot to include custom icons, texts & elements -->
                            <slot name="right">
                                <i
                                    v-if="rightIcon"
                                    :class="[`btn-icon icon icon-${rightIcon}`]"
                                />
                            </slot>
                        </div>
                    </div>
                </slot>
            </div>
        </div>
    </component>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import type { StyleValue, PropType } from 'vue';
import type { EnumToUnion } from '@/types/misc';
import { slotIsUsed } from '@/helpers/dom';
import { colorToCss } from '@/utils/colorUtils';
// eslint-disable-next-line no-restricted-imports
import Link from '@/components/Link';
import { BtnSize, BtnType, BtnVariant, BtnContentSpacing, BtnLabelAlignment, BtnLabelFlexStyle } from './Btn';

/**
 * This is our main button component. It provides all the necessities and can be adjusted for all your needs.
 * It has a couple pre-defined variants and will apply hover and active effects automatically based on the specified variant and color.
 * But you can also customize it further by utilizing the available slots or custom variant for custom styling.
 *
 * Besides the props listed here, the native html button attributes are also supported and will be forwarded to the underlying element.
 * On top of that, accessibility & improved touch support is also considered and build-in.
 */
export default defineComponent({
    name: 'Btn',
    inheritAttrs: false,
    props: {
        /**
         * The variant of the button.
         * @values filled, outlined, tonal, text, custom
         */
        variant: {
            type: String as PropType<EnumToUnion<BtnVariant>>,
            default: BtnVariant.FILLED,
            validator: (val: BtnVariant) => Object.values(BtnVariant).includes(val),
        },
        /**
         * The native type of the button.
         * Has no effect if `href` or `to` are set.
         * @values button, submit, reset
         * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attributes
         */
        type: {
            type: String as PropType<EnumToUnion<BtnType>>,
            default: BtnType.BUTTON,
        },
        /**
         * The text on the button
         */
        label: {
            type: String,
            default: null,
        },
        /**
         * Whether or not the label to appear and to disappear with a fading effect.
         */
        labelTransition: {
            type: Boolean,
            default: false,
        },
        /**
         * Whether the label should only appear when interacting (hover, focus) with the button.
         * Has no effect if we have more than one icon present.
         */
        labelOnTouch: {
            type: Boolean,
            default: false,
        },
        /**
         * Same as `icon-left`
         * @example arrow-right
         */
        icon: {
            type: String,
            default: null,
            validator: (val: string) => !val.includes(' '),
        },
        /**
         * Left side label icon
         * @example arrow-left
         */
        leftIcon: {
            type: String,
            default: null,
            // as the icon prop specifies an icon class, we don't want to allow other classes
            validator: (val: string) => !val.includes(' '),
        },
        /**
         * Right side label icon
         * @example chat
         */
        rightIcon: {
            type: String,
            default: null,
            // as the icon prop specifies an icon class, we don't want to allow other classes
            validator: (val: string) => !val.includes(' '),
        },
        /**
         * The size of the button.
         * @values xxs, xs, sm, md, lg
         */
        size: {
            type: String as PropType<EnumToUnion<BtnSize>>,
            default: BtnSize.MD,
            validator: (val: BtnSize) => Object.values(BtnSize).includes(val),
        },
        /**
         * Removes any height & content padding so the button size fits the content.
         */
        compact: {
            type: Boolean,
            default: false,
        },
        /**
         * Applies a round or pill button style.
         */
        round: {
            type: Boolean,
            default: false,
        },
        /**
         * The color of the button. Supports tailwind, css colors, and css background values (images, gradients, etc).
         * If you use a background value, `contentColor` needs to be specified to properly color the text then.
         * It would use currentColor if undefined.
         * @example primary, secondary, #ff0000, rgba(255, 0, 0, 0.5)
         */
        color: {
            type: String,
            default: null,
        },
        /**
         * The content color. Supports tailwind and css colors.
         * It would use currentColor if undefined.
         * @example primary, secondary, #ff0000, rgba(255, 0, 0, 0.5)
         */
        contentColor: {
            type: String,
            default: null,
        },
        /**
         * Defines the color that should be used when the button is active.
         */
        activeColor: {
            type: String,
            default: null,
        },
        /**
         * Defines the content color that should be used when the button is active.
         */
        activeContentColor: {
            type: String,
            default: null,
        },
        /**
         * Applies specified colors only when the button was touched/interacted with (hover, pressed, active)
         */
        colorOnTouch: {
            type: Boolean,
            default: false,
        },
        /**
         * Applies specified colors only when the button is active
         */
        colorOnActive: {
            type: Boolean,
            default: false,
        },
        /**
         * Adds a shadow to the button
         */
        elevated: {
            type: Boolean,
            default: false,
        },
        /**
         * Defines the spacing of icon and label.
         * @values center, apart
         */
        contentSpacing: {
            type: String as PropType<EnumToUnion<BtnContentSpacing>>,
            default: BtnContentSpacing.CENTER,
            validator: (val: BtnContentSpacing) => Object.values(BtnContentSpacing).includes(val),
        },
        /**
         * Disables the button
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the active state of the button
         */
        active: {
            type: Boolean,
            default: false,
        },
        /**
         * Triggers loading state of the button
         */
        loading: {
            type: Boolean,
            default: false,
        },
        /**
         * Define the aligment style of label,
         * works when content-spacing is set to apart
         * @values left, right, center
         */
        labelAlign: {
            type: String as PropType<EnumToUnion<BtnLabelAlignment>>,
            default: BtnLabelAlignment.LEFT,
        },
    },
    data() {
        return {
            maxLabelWidth: 0,
        };
    },
    computed: {
        tag(): string | typeof Link {
            if (this.$attrs.to || this.$attrs.href) return Link;

            return 'button';
        },
        primaryIcon(): string {
            return this.leftIcon ?? this.icon;
        },
        buttonClasses() {
            return [
                `variant-${this.variant}`,
                `size-${this.size}`,
                {
                    compact: this.compact,
                    active: this.active,
                    disabled: this.disabled,
                    'labelOnTouch whitespace-nowrap': this.hasLabelOnTouch,
                    iconOnly:
                        (this.hasRightIconOrSlot || this.hasLeftIconOrSlot) &&
                        (!this.hasLabelOrSlot || this.labelOnTouch),
                    loading: this.loading,
                    'rounded-full': this.round,
                    'color-on-touch': this.colorOnTouch,
                    'color-on-active': this.colorOnActive,
                    'elevated shadow-light-base': this.elevated,
                },
            ];
        },
        currentColor() {
            const activeColor = this.activeColor || this.color;
            const initialColor = this.colorOnActive ? undefined : this.color;

            return this.active ? activeColor : initialColor;
        },
        buttonColor(): string | undefined {
            return colorToCss(this.currentColor);
        },
        buttonContentColor(): string | undefined {
            const activeColor = this.activeContentColor || this.contentColor;
            const initialColor = this.colorOnActive ? undefined : this.contentColor;

            return colorToCss(this.active ? activeColor : initialColor);
        },
        buttonDefaultFilledOnColor(): string | undefined {
            return colorToCss(`on-${this.currentColor}`);
        },
        buttonStyles(): StyleValue {
            return {
                '--btn-color': this.buttonColor,
                '--btn-color-content': this.buttonContentColor,
                '--btn-color-on-filled-default': this.buttonDefaultFilledOnColor,
                '--btn-label-width': `${this.maxLabelWidth}px`,
            };
        },
        hasLeftIconOrSlot(): boolean {
            return !!this.icon || !!this.leftIcon || slotIsUsed(this.$slots.left);
        },
        hasRightIconOrSlot(): boolean {
            return !!this.rightIcon || slotIsUsed(this.$slots.right);
        },
        hasLabelOrSlot(): boolean {
            return !!this.label || slotIsUsed(this.$slots.default);
        },
        justifyContent(): string {
            switch (this.contentSpacing) {
                case BtnContentSpacing.APART:
                    return 'space-between';
                case BtnContentSpacing.CENTER:
                default:
                    return 'center';
            }
        },
        labelFlex(): BtnLabelFlexStyle {
            switch (this.contentSpacing) {
                case BtnContentSpacing.APART:
                    return BtnLabelFlexStyle.GROW;
                default:
                    return BtnLabelFlexStyle.FLEX;
            }
        },
        labelDirection(): string | undefined {
            return this.labelOnTouch && this.hasRightIconOrSlot ? 'direction: rtl;' : undefined;
        },
        labelSpacing(): string {
            switch (this.size) {
                case BtnSize.XXS:
                case BtnSize.XS:
                    return (this.hasRightIconOrSlot ? 'pr-1' : '') + (this.hasLeftIconOrSlot ? ' pl-1' : '');
                default:
                    return (this.hasRightIconOrSlot ? 'pr-2' : '') + (this.hasLeftIconOrSlot ? ' pl-2' : '');
            }
        },
        labelAlignClass(): string {
            switch (this.labelAlign) {
                case BtnLabelAlignment.RIGHT:
                    return 'text-right';
                case BtnLabelAlignment.CENTER:
                    return 'text-center';
                case BtnLabelAlignment.LEFT:
                default:
                    return 'text-left';
            }
        },
        hasLabelOnTouch(): boolean {
            return this.labelOnTouch && !(this.hasLeftIconOrSlot && this.hasRightIconOrSlot);
        },
        isHtmlButton(): boolean {
            const component = this.$attrs.is ?? this.tag;
            return component === 'button';
        },
    },
    methods: {
        onLabelChange(): void {
            const spacing = 8;
            this.maxLabelWidth = (this.$refs.label as HTMLElement).clientWidth + spacing;
        },
    },
});
</script>
<style lang="scss" scoped>
/**
 * Simulated color lightness increase by 10%
 */
@mixin lighter {
    // the brightness filter alone would also change the color saturation, so we need to adjust the contrast as well
    filter: contrast(66.3%) brightness(120%);
}

/**
 * Simulated color lightness decrease by 10%
 */
@mixin darker {
    filter: brightness(80%);
}

/**
 * Applies tailwind classes for content for a specific button size
 */
@mixin defineSize($size, $btnClasses, $buttonHeight, $borderRadius, $padding, $iconOnlyPadding, $iconSize) {
    &.size-#{$size} {
        @apply #{$btnClasses};

        &:not(.variant-text):not(.rounded-full) {
            border-radius: #{$borderRadius};
        }

        &:not(.compact) {
            @apply #{$buttonHeight};

            .btn-content {
                @apply #{$padding};
            }

            &.iconOnly .btn-content {
                @apply #{$iconOnlyPadding};
            }
        }

        .btn-icon {
            @apply #{$iconSize};
        }

        .spinner :deep(svg) {
            @apply #{$iconSize};
        }
    }
}

/**
 * As our colors can be enabled only when interacting with the button,
 * We need to disable it initially and allow all other interaction states
 */
@mixin initialColor {
    &:not(.color-on-touch),
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.touchhold {
        @content;
    }
}

.btn {
    @apply relative inline-block select-none align-middle;
    --btn-color-default: rgb(var(--colors-slate-400));
    --btn-color-border-default: rgb(var(--colors-slate-300));

    .btn-container {
        @apply flex min-h-inherit h-full items-center;
        border-radius: inherit;
    }

    .btn-content {
        @apply relative h-full w-full flex justify-center items-center overflow-hidden align-middle;
    }

    &.variant-outlined .btn-container::before {
        @apply border border-solid absolute inset-0 z-0;
        border-radius: inherit;
        content: '';
    }

    /*----------SIZING----------*/

    @include defineSize('lg', 'font-button-medium', 'min-h-14', '14px', 'px-5 py-4', 'px-4', 'w-6 h-6');

    @include defineSize('md', 'font-button-medium', 'min-h-12', '12px', 'px-4 py-3', 'px-3', 'w-6 h-6');

    @include defineSize('sm', 'font-button-medium', 'min-h-10', '10px', 'px-3 py-2', 'px-2', 'w-6 h-6');

    @include defineSize('xs', 'font-body-3-medium', 'min-h-8', '8px', 'px-2.5 py-1.5', 'px-1.5', 'w-5 h-5');

    @include defineSize('xxs', 'font-caption-medium', 'min-h-6', '6px', 'px-2 py-1', 'px-1', 'w-4 h-4');

    &.compact {
        height: auto;

        .btn-content {
            @apply p-0;
        }
    }

    /*----------COLORS----------*/

    &.elevated {
        /** 
         * When the button is elevated, a transparent background does not make sense.
         * That's why we apply a base color.
         */
        background: rgb(var(--colors-surface));
    }

    &:not(:disabled):not(.disabled) {
        @apply cursor-pointer;

        /**
         * As we need to control the background shade separately from the content,
         * the background will be rendered as a pseudo element to provide this ability.
         */
        &::before {
            @apply absolute inset-0 z-0 pointer-events-none;
            border-radius: inherit;
            content: '';
        }

        &:not(.variant-custom):not(.loading) {
            &:hover .btn-container {
                @include lighter;
            }

            &:active .btn-container {
                @include darker;
            }
        }

        &.variant-filled {
            // the background will be overwritten by a bg-* class which is needed to provide the indended text color.
            // but we don't want to use this background color and need to hide it.
            background: transparent !important;

            // some background pre-define their text color. So we should only specify a default text-color when the button is not colored
            &.color-on-touch:not(:hover):not(:active):not(:focus):not(.active),
            &.color-on-active:not(.active):not(:active) {
                color: var(--btn-color-text-default);
            }

            @include initialColor {
                .btn-container {
                    color: var(--btn-color-content, var(--btn-color-on-filled-default, var(--btn-color-text-default)));
                }

                &::before {
                    background: var(--btn-color, rgb(var(--colors-surface)));
                }
            }

            &:hover::before {
                @include lighter;
            }

            &:active::before,
            &.active::before {
                @include darker;
            }
        }

        &.variant-outlined {
            @include initialColor {
                .btn-container::before {
                    border-color: var(--btn-color, var(--btn-color-border-default));
                }
            }
        }

        &.variant-outlined,
        &.variant-tonal {
            @include initialColor {
                .btn-container {
                    color: var(--btn-color-content, var(--btn-color, var(--btn-color-text-default)));
                }
            }

            &:hover::before {
                background: var(--btn-color, var(--btn-color-default));
                opacity: 0.05;
            }

            &:active::before {
                background: var(--btn-color, var(--btn-color-default));
                opacity: 0.15;
            }

            &.active::before {
                background: var(--btn-color, var(--btn-color-default));
                opacity: 0.1;
            }
        }

        &.variant-text {
            @include initialColor {
                color: var(--btn-color-content, var(--btn-color, var(--btn-color-text-default)));
            }

            &.active .btn-container {
                @include darker;
            }
        }
    }

    &:disabled.variant,
    &.disabled.variant {
        &-filled,
        &-tonal.active {
            background: rgb(var(--colors-disabled));
            color: rgb(var(--colors-white));
        }

        &-outlined,
        &-tonal:not(.active),
        &-text {
            color: rgb(var(--colors-disabled));
        }

        &-outlined .btn-container::before {
            border-color: rgb(var(--colors-disabled));
        }
    }
}

.labelOnTouch {
    .label {
        max-width: 0;
        transition: max-width 0.2s ease-out;
        overflow: hidden;
    }

    @media (hover: hover) {
        &:hover .label {
            max-width: var(--btn-label-width);
            overflow: visible;
        }
    }

    &.touchhold,
    &:focus {
        .label {
            max-width: var(--btn-label-width);
            overflow: visible;
        }
    }
}
.btn-label-enter-active,
.btn-label-leave-active {
    transition: opacity 0.3s ease;
}

.btn-label-enter-from,
.btn-label-leave-to {
    opacity: 0;
}
</style>
